import TextStyle from '@tiptap/extension-text-style'
import Underline from '@tiptap/extension-underline'
import { useEditor, EditorContent } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import { Form } from 'antd'

import { TiptapToolbar } from '../TiptapToolbar'
import * as S from './styles'

interface TiptapProps {
  label: string
  defaultValue?: string
  onChange: (editorData: string) => void
}

export const Tiptap = ({ label, defaultValue, onChange }: TiptapProps) => {
  const form = Form.useFormInstance()

  const editor = useEditor({
    extensions: [
      TextStyle,
      StarterKit.configure({
        bulletList: { keepMarks: true, keepAttributes: false },
        orderedList: { keepMarks: true, keepAttributes: false },
      }),
      Underline,
    ],
    content: form.getFieldValue(label) || defaultValue || '',
    onUpdate: ({ editor }) => {
      onChange(editor.getHTML())
    },
    editorProps: {
      attributes: {
        style: `
          display: flex; justify-content: flex-start;
          flex-direction: column;
          padding: 1rem;
          background-color: white;
          min-height: 125px;
          border-radius: 5px; border: 1px solid #d9d9d9;
          cursor: text;
          `,
      },
    },
  })

  return (
    <S.Container>
      <TiptapToolbar editor={editor} />
      <EditorContent editor={editor} />
    </S.Container>
  )
}
