import { useEffect } from 'react'

import { Button, Form } from 'antd'
import { toast } from 'sonner'

import { EditSpecificationsForm, EditSpecificationsFormData } from '../../Form'
import * as S from './styles'

import {
  ApiError,
  BaseModalProps,
  filtersRemap,
  toastError,
  useToggle,
  specificationsRemap,
} from '@/common'
import { BaseModal } from '@/components'
import { useProductTypeGroupsField } from '@/features/BG'
import {
  editSpecificationsErrorMessages,
  useEditSpecificationsMutation,
} from '@/features/specifications/mutations'
import { SpecificationType } from '@/features/specifications/types'

interface SpecificationsModalProps extends BaseModalProps {
  data: SpecificationType
}

const SpecificationsModal = ({ isOpen, onClose, data }: SpecificationsModalProps) => {
  const [form] = Form.useForm()
  const [isEditing, toggleEdit] = useToggle(false)
  const editSpecificationsMutation = useEditSpecificationsMutation()

  const { data: groupSpecifications } = useProductTypeGroupsField(
    data.product_type.vtex_id as number,
  )

  const selectedGroupSpecification = groupSpecifications?.find(
    (category) => category.id === data.field_group.id,
  )?.name

  useEffect(() => {
    const initialData = {
      ...data,
      name: data.name,
      position: data.position,
      field_type_id: data.field_type_id,
      product_type: data.product_type.vtex_id,
      field_group_id: data.field_group.id,
    }

    if (initialData) {
      form.setFieldsValue(initialData)
    }
  }, [data, form])

  async function handleEditSpecifications(values: EditSpecificationsFormData) {
    const payload = {
      id: data.id,
      field_type_id: values.field_type_id,
      product_type_vtex_id: values.product_type,
      field_group_id: values.field_group_id,
      name: values.name,
      position: values.position,
    }

    const promise = editSpecificationsMutation.mutateAsync(payload)

    toast.promise(promise, {
      loading: 'Editando especificação...',
      success: () => {
        onClose()

        return (
          <S.ToastContainer>
            <S.CircleOutlined />

            <div>
              Especificação <span style={{ fontWeight: 'bold' }}>editada</span> com{' '}
              <span style={{ fontWeight: 'bold' }}>sucesso</span>!
            </div>
          </S.ToastContainer>
        )
      },
      className: 'toast-sucess',
      error: (error: ApiError) =>
        toastError({
          error,
          errorMessages: editSpecificationsErrorMessages,
          defaultMessage: 'Erro ao salvar especificação.',
        }),
    })
  }

  const SpecificationsView = () => (
    <>
      <S.FieldRow>
        <S.SpecificationsData>
          <strong>Tipo de Campo</strong>

          <span>{specificationsRemap.get(data.field_type_id) as string}</span>
        </S.SpecificationsData>

        <S.SpecificationsData>
          <strong>Tipo de Produto</strong>

          <span>
            {(filtersRemap.get(data.product_type.name) as string) || data.product_type.name}
          </span>
        </S.SpecificationsData>

        <S.SpecificationsData>
          <strong>Grupo de Especificações</strong>

          <span>{selectedGroupSpecification}</span>
        </S.SpecificationsData>
      </S.FieldRow>

      <S.FieldRow>
        <S.SpecificationsData>
          <strong>Nome</strong>

          <span>{data.name}</span>
        </S.SpecificationsData>
      </S.FieldRow>

      <S.FieldRow>
        <S.SpecificationsData>
          <strong>Posição do Campo</strong>

          <span>{data.position}</span>
        </S.SpecificationsData>
      </S.FieldRow>
    </>
  )

  return (
    <BaseModal
      open={isOpen}
      onCancel={onClose}
      title="Dados cadastrais"
      width={717}
      footer={
        <>
          {!isEditing ? (
            <Button
              onClick={onClose}
              type="link"
              htmlType="button"
              disabled={editSpecificationsMutation.isLoading}
            >
              {/* Cancelar */}
              Fechar
            </Button>
          ) : (
            <Button type="link" onClick={toggleEdit}>
              Voltar
            </Button>
          )}

          {/* {!isEditing ? (
          <>
          {isAdmin && (
            <Button type="primary" onClick={toggleEdit}>
            Editar
            </Button>
            )}
          </>
          ) : (
            <Button
              type="primary"
              htmlType="submit"
              onClick={() => form.submit()}
              disabled={editSpecificationsMutation.isLoading}
            >
              Salvar
            </Button>
          )} */}
        </>
      }
    >
      <S.Container>
        {!isEditing ? (
          <SpecificationsView />
        ) : (
          <EditSpecificationsForm
            form={form}
            onEdit={handleEditSpecifications}
            isLoading={editSpecificationsMutation.isLoading}
          />
        )}
      </S.Container>
    </BaseModal>
  )
}

export { SpecificationsModal }
