import { Button, Form } from 'antd'
import { toast } from 'sonner'

import { CreateSpecificationsForm, SpecificationsFormData } from '../../Form'
import * as S from './styles'

import { ApiError, toastError } from '@/common'
import { BaseModal } from '@/components'
import {
  CreateSpecificationsErrorMessages,
  useCreateSpecificationsMutation,
} from '@/features/specifications/mutations'

type CreateSpecificationsModalProps = {
  isOpen: boolean
  onClose: () => void
}

const CreateSpecificationsModal = ({ isOpen, onClose }: CreateSpecificationsModalProps) => {
  const [form] = Form.useForm()

  const createSpecificationMutation = useCreateSpecificationsMutation()

  async function handleCreateSpecifications(data: SpecificationsFormData) {
    try {
      const promise = createSpecificationMutation.mutateAsync({
        field_type_id: data.fieldType,
        product_type_vtex_id: data.productType,
        field_group_id: data.groupSpecification,
        name: data.name,
        position: data.position,
      })

      toast.promise(promise, {
        loading: 'Salvando Especificação...',
        success: () => {
          onClose()

          return (
            <S.ToastContainer>
              <S.CircleOutlined />

              <div>
                Especificação <span style={{ fontWeight: 'bold' }}>salva</span> com{' '}
                <span style={{ fontWeight: 'bold' }}>sucesso</span>!
              </div>
            </S.ToastContainer>
          )
        },
        className: 'toast-sucess',
        error: (error: ApiError) =>
          toastError({
            error,
            errorMessages: CreateSpecificationsErrorMessages,
            defaultMessage: 'Erro ao salvar especificação.',
          }),
      })
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <BaseModal
      open={isOpen}
      onCancel={onClose}
      title={'Criar Especificação'}
      width={717}
      footer={
        <>
          <S.CancelButton
            type="link"
            onClick={onClose}
            disabled={createSpecificationMutation.isLoading}
          >
            Cancelar
          </S.CancelButton>

          <Button
            type="primary"
            htmlType="submit"
            onClick={() => form.submit()}
            disabled={createSpecificationMutation.isLoading}
          >
            Salvar
          </Button>
        </>
      }
    >
      <S.Container>
        <S.Text>Os campos de * são de preenchimento obrigatório</S.Text>

        <CreateSpecificationsForm
          form={form}
          onSubmit={handleCreateSpecifications}
          isSubmitting={createSpecificationMutation.isLoading}
        />
      </S.Container>
    </BaseModal>
  )
}

export { CreateSpecificationsModal }
