import { QueryFunctionContext, useQuery } from '@tanstack/react-query'

import { ProductListResponse } from '../../types'
import { productKeys } from '../query-keys'

import { useBGProductFilters } from '@/app/stores/filter/bg-product-filter'
import { generateSearchParams } from '@/common'
import { api } from '@/services'

const fetchBGProducts = async ({
  queryKey,
}: QueryFunctionContext<ReturnType<(typeof productKeys)['list']>>) => {
  const [{ filters }] = queryKey

  const { page, logs_per_page, ...activeFilters } = filters

  const url = generateSearchParams(activeFilters)

  const { data } = await api.get<ProductListResponse>(
    `/products?per_page=${logs_per_page}&page=${page}&${url}`,
  )

  return data
}

export const useProducts = () => {
  const filters = useBGProductFilters((state) => state.filters)

  return useQuery({
    queryKey: productKeys.list(filters),
    queryFn: fetchBGProducts,
    keepPreviousData: true,
  })
}
