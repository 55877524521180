import { Button } from 'antd'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const CleanButton = styled(Button)`
  color: ${({ theme }) => theme.colors.darkGreen};
  border: 1px solid ${({ theme }) => theme.colors.darkGreen};
`

export const SwitchTitleArea = styled.div`
  display: flex;
  gap: 8px;
`
