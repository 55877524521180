import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { TabsProps } from 'antd'

import * as S from './styles'

// import { LoadingSpinner } from '@/components'
import { TitleTabs } from '@/components/Layout/PageLayout'
import { Permission } from '@/features/users'
import { PermissionsListTable } from '@/features/users/components/Table/PermissionsListTabe'
import { PageLayout } from '@/layouts'

const mockPermission = {
  id: '1',
  title: 'Visualizar',
  area: 'Empresas',
  description: 'Pode apenas criar empresas criados pelo usuário logado',
}

const mockPermissions: Permission[] = Array(15).fill(mockPermission)

export const PermissionsPage = () => {
  const navigate = useNavigate()
  const [selectedKey] = useState('3')

  const titleTabs: TabsProps['items'] = [
    {
      key: '1',
      label: 'Usuários',
    },
    {
      key: '2',
      label: 'Grupos',
    },
    {
      key: '3',
      label: (
        <S.SwitchTitleArea>
          Permissões {/* (isLoading || isFetching) &&  <LoadingSpinner /> */}
        </S.SwitchTitleArea>
      ),
    },
  ]

  const handleTabChange = (key: string) => {
    switch (key) {
      case '1':
        navigate('/users')
        break
      case '2':
        navigate('/groups')
        break
      case '3':
        navigate('/permissions')
        break
      default:
        break
    }
  }

  return (
    <PageLayout title="Permissões do sistema">
      <S.Container>
        <TitleTabs
          activeKey={selectedKey}
          defaultActiveKey="3"
          onChange={handleTabChange}
          items={titleTabs}
        />
        <PermissionsListTable data={mockPermissions} />
      </S.Container>
    </PageLayout>
  )
}
