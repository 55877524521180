import { useQuery } from '@tanstack/react-query'

import type { AnymarketCategory } from '../../types'
import { categoryKeys } from '../query-keys'

import { api } from '@/services'

async function fetchAnymarketCategories() {
  const { data } = await api.get<AnymarketCategory[]>('/categories/anymarket')

  return data
}

export const useAnymarketCategories = () => {
  return useQuery({
    queryKey: categoryKeys.anymarketCategories(),
    queryFn: fetchAnymarketCategories,
    keepPreviousData: true,
  })
}
