import { QueryFunctionContext, useQuery } from '@tanstack/react-query'

import { GlobalCategories } from '../../types'
import { categoryKeys } from '../query-keys'

import { api } from '@/services'

async function fetchGlobalCategories({
  queryKey,
}: QueryFunctionContext<ReturnType<(typeof categoryKeys)['globalCategories']>>) {
  const [{ name }] = queryKey

  // Base URL with mandatory query parameters
  let url = '/google_global_categories?per_page=20&page=1'

  // Append the 'name' query parameter only if it is not empty
  if (name) {
    url += `&name=${encodeURIComponent(name)}`
  }

  // only add name to the query if it's not empty
  const { data } = await api.get<GlobalCategories>(url)

  return data
}

export const useGlobalCategories = (name = '') =>
  useQuery({
    queryKey: categoryKeys.globalCategories(name),
    queryFn: fetchGlobalCategories,
    keepPreviousData: true,
  })
