import { FileDoneOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { ColumnsType } from 'antd/es/table'

import * as S from './styles'

import { filtersRemap } from '@/common'
import { BaseTable, TableAction } from '@/components'
import { ProductType } from '@/features/BG/types'

type ProductTypeTableProps = {
  data: ProductType[] | undefined
  isLoading: boolean
  handleEditProductType: (category: ProductType) => void
}

const ProductTypeTable = ({ data, isLoading, handleEditProductType }: ProductTypeTableProps) => {
  const columns: ColumnsType<ProductType> = [
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
      width: 620,
      render: (name) => <p>{filtersRemap.get(name) ? filtersRemap.get(name) : name}</p>,
    },
    {
      title: 'Categoria Global',
      dataIndex: 'global_category',
      key: 'global_category',
      width: 620,
      render: (_, record) => <p>{record.global_category.name}</p>,
    },
    {
      title: 'Ações',
      dataIndex: '',
      key: 'actions',
      align: 'center',
      width: 55,
      render: (_, productType) => {
        const dropdownOptions = [
          {
            key: '1',
            label: (
              <S.ButtonContent>
                <Button
                  icon={<FileDoneOutlined />}
                  type="link"
                  onClick={() => handleEditProductType(productType)}
                >
                  Dados cadastrais
                </Button>
              </S.ButtonContent>
            ),
          },
        ]

        return <TableAction dropdownOptions={dropdownOptions} />
      },
    },
  ]

  return (
    <BaseTable
      dataSource={data}
      columns={columns}
      rowKey={(record, i) => `${record.id}-${i}`}
      pagination={false}
      loading={isLoading}
    />
  )
}

export { ProductTypeTable }
