import { useReducer, useState } from 'react'
import { Link } from 'react-router-dom'

import { AppstoreOutlined, UnorderedListOutlined } from '@ant-design/icons'
import { Button, Form, Typography } from 'antd'
import dayjs from 'dayjs'
import { toast } from 'sonner'
import { shallow } from 'zustand/shallow'

import * as S from './styles'

import {
  BGProductFilters,
  resetBGProductFilters,
  useBGProductFilters,
} from '@/app/stores/filter/bg-product-filter'
import {
  getActiveFiltersList,
  quantityByStatusRemap,
  removeActiveFilter,
  useAuth,
  useToggle,
} from '@/common'
import {
  ActiveFiltersList,
  CustomPagination,
  FilterSidebar,
  LoadingSpinner,
  PageErrorBoundary,
  SearchInput,
} from '@/components'
import {
  useProducts,
  BgListTable,
  useProductsQuantityByStatus,
  BgCard,
  BgHistoricModal,
  useDeleteProductMutation,
  ProductStatus,
  Product,
  BGProductFilterForm,
  BGProductFilterFormData,
} from '@/features/BG'
import { PageLayout } from '@/layouts'

export type SelectProductActions = {
  product: Product
  type: 'VIEW_HISTORY' | 'DELETE_PRODUCT' | 'FINISH_REGISTRATION'
}

export function DashboardPage() {
  const [form] = Form.useForm<BGProductFilterFormData>()

  const { getRolePermissions } = useAuth()

  const rolePermissions = getRolePermissions()

  const {
    data: bgProducts,
    isLoading: isProductsLoading,
    isFetching,
    isError: isBgProductsError,
  } = useProducts()

  const { data: quantityByStatus, isError: quantityByStatusError } = useProductsQuantityByStatus()

  const { productsFilters, setFilters } = useBGProductFilters(
    (state) => ({ productsFilters: state.filters, setFilters: state.setFilters }),
    shallow,
  )

  const deleteProductMutation = useDeleteProductMutation()

  const [productSegmentView, setProductSegmentView] = useState<'table' | 'cards'>('cards')
  const [isFilterDrawerOpen, toggleFilterDrawer] = useToggle(false)
  const [isHistoricModalOpen, toggleHistoricModal] = useToggle(false)

  const [selectedProduct, selectProductDispatch] = useReducer(
    (state: Product, action: SelectProductActions) => {
      switch (action.type) {
        case 'VIEW_HISTORY':
          toggleHistoricModal()
          return action.product
        case 'DELETE_PRODUCT':
          handleDeleteProduct(action?.product?.id as string)
          return state
        case 'FINISH_REGISTRATION':
          // toggleBgDataModal()
          return action.product
        default:
          return {} as Product
      }
    },
    {} as Product,
  )

  function submitFilters() {
    form.submit()
    toggleFilterDrawer()
  }

  function handleFilter() {
    const values = form.getFieldsValue()

    const filters: BGProductFilters = {
      ...values,
      initial_date: values.initial_date
        ? dayjs(values.initial_date).startOf('day').format('YYYY/MM/DD HH:mm:ss')
        : undefined,
      final_date: values.final_date
        ? dayjs(values.final_date).endOf('day').format('YYYY/MM/DD HH:mm:ss')
        : undefined,
    }

    setFilters({ ...filters, page: 1 })
  }

  function handleRemoveFilter(filterKey: keyof BGProductFilters, filterValue?: string) {
    const updatedFilters = removeActiveFilter({
      activeFilters: productsFilters,
      filterKey,
      filterValue,
    })

    setFilters({ ...updatedFilters })
  }

  function handleClearFilters() {
    form.setFieldsValue({
      initial_date: undefined,
      final_date: undefined,
      product_type_name: undefined,
      category_name: undefined,
      product_status: undefined,
    })

    resetBGProductFilters()
    toggleFilterDrawer()
  }

  function handleSearch(search: string) {
    setFilters({ search: search })
  }

  function handlePagination(page: number, pageSize?: number) {
    setFilters({ page, logs_per_page: pageSize })
  }

  function handleFilterByStatus(status: ProductStatus) {
    setFilters({ product_status: status })
  }

  async function handleDeleteProduct(productId: string) {
    if (deleteProductMutation.isLoading) return

    const promise = deleteProductMutation.mutateAsync(productId)

    return toast.promise(promise, {
      loading: 'Excluindo...',
      success: (
        <S.ToastContainer>
          <S.CircleOutlined />

          <div>
            Produto <span style={{ fontWeight: 'bold' }}>excluído</span> com{' '}
            <span style={{ fontWeight: 'bold' }}>sucesso</span>!
          </div>
        </S.ToastContainer>
      ),
      className: 'toast-sucess',
      error: 'Não foi possível excluir o produto',
    })
  }

  const PageHeader = () => {
    return (
      <S.Header>
        <S.TitleContainer>
          <S.Title level={4}>Visão Geral</S.Title>

          <S.Divider thickness={2} type="vertical" />

          {bgProducts && (
            <S.TotalBgs>
              <S.TotalBgsValue>{bgProducts?.total}</S.TotalBgsValue>
              {bgProducts?.total > 1 ? 'encontrados' : 'encontrado'}
            </S.TotalBgs>
          )}

          {(isProductsLoading || isFetching) && <LoadingSpinner />}
        </S.TitleContainer>

        {rolePermissions?.product?.isAdmin || rolePermissions?.product?.canCreate ? (
          <Link to="/create-product">
            <S.RegisterButton type="primary">Cadastrar Buyers Guide</S.RegisterButton>
          </Link>
        ) : null}
      </S.Header>
    )
  }

  const SkeletonPage = () => {
    return (
      <S.Container>
        <S.InfoCardsContainer>
          <S.InfoCardLoading active />
          <S.InfoCardLoading active />
          <S.InfoCardLoading active />
          <S.InfoCardLoading active />
          <S.InfoCardLoading active />
          <S.InfoCardLoading active />
          <S.InfoCardLoading active />
          <S.InfoCardLoading active />
          <S.InfoCardLoading active />
        </S.InfoCardsContainer>

        <S.PageActionsContainer>
          <S.ChangeViewButtonLoading active shape="square" />
          <S.ChangeViewButtonLoading active shape="square" />
          <S.ChangeViewButtonLoading active shape="square" />
        </S.PageActionsContainer>

        <S.ActiveFiltersListLoading />

        <S.GridCardsContainer>
          <S.BgCardLoading active />
          <S.BgCardLoading active />
          <S.BgCardLoading active />
          <S.BgCardLoading active />

          <S.BgCardLoading active />
          <S.BgCardLoading active />
          <S.BgCardLoading active />
          <S.BgCardLoading active />

          <S.BgCardLoading active />
          <S.BgCardLoading active />
          <S.BgCardLoading active />
          <S.BgCardLoading active />

          <S.BgCardLoading active />
          <S.BgCardLoading active />
          <S.BgCardLoading active />
          <S.BgCardLoading active />
        </S.GridCardsContainer>
      </S.Container>
    )
  }

  return (
    <>
      <PageLayout
        title="Dashboard"
        sidebar={
          <FilterSidebar
            isFilterDrawerOpen={isFilterDrawerOpen}
            onClose={toggleFilterDrawer}
            filterFooter={
              <S.FooterSidebar>
                <S.CleanButton onClick={handleClearFilters}>Limpar</S.CleanButton>

                <Button type="primary" htmlType="submit" onClick={submitFilters}>
                  Filtrar
                </Button>
              </S.FooterSidebar>
            }
          >
            <BGProductFilterForm onSubmit={handleFilter} form={form} />
          </FilterSidebar>
        }
      >
        {isHistoricModalOpen && (
          <BgHistoricModal
            isOpen={isHistoricModalOpen}
            onClose={() => selectProductDispatch({ type: 'VIEW_HISTORY', product: {} as Product })}
            productId={selectedProduct?.id as string}
          />
        )}

        <S.Container>
          <PageHeader />

          {isProductsLoading ? (
            <SkeletonPage />
          ) : (
            <>
              <S.InfoCardsContainer>
                {quantityByStatusError ? (
                  <S.ErrorContainer>
                    <S.ExclamationIcon />
                    <Typography>
                      Ops, tivemos um problema ao carregar este painel. Por favor, recarregue a
                      página!
                    </Typography>
                  </S.ErrorContainer>
                ) : (
                  quantityByStatus?.map((overview, index) => {
                    const hasOverview = overview.amount !== 0 && overview.amount !== null

                    return (
                      <S.InfoCard
                        key={index}
                        $type={overview.status}
                        onClick={
                          hasOverview
                            ? () => handleFilterByStatus(overview.status as ProductStatus)
                            : undefined
                        }
                      >
                        <S.InfoCardValue $isClickActive={hasOverview}>
                          {hasOverview ? overview.amount : <S.NoValueText>--</S.NoValueText>}
                        </S.InfoCardValue>

                        <S.InfoCardStatus>
                          {quantityByStatusRemap.get(overview.status as ProductStatus)}
                        </S.InfoCardStatus>
                      </S.InfoCard>
                    )
                  })
                )}
              </S.InfoCardsContainer>

              <S.PageActionsContainer>
                <SearchInput
                  onSearch={handleSearch}
                  initialValue={productsFilters.search}
                  onClose={() => setFilters({ search: undefined })}
                  placeholder='"Modelo", "EAN", "Part Number" ou "SKU"'
                />

                <S.ProductViewSegment
                  value={productSegmentView}
                  onChange={(value) => setProductSegmentView(value as 'table' | 'cards')}
                  options={[
                    { value: 'cards', icon: <AppstoreOutlined /> },
                    { value: 'table', icon: <UnorderedListOutlined /> },
                  ]}
                />
              </S.PageActionsContainer>

              <ActiveFiltersList
                filtersList={getActiveFiltersList(productsFilters)}
                removeFilter={handleRemoveFilter}
                cleanFiltersList={handleClearFilters}
              />

              {isBgProductsError || !bgProducts ? (
                <S.LayoutErrorContainer>
                  <PageErrorBoundary />
                </S.LayoutErrorContainer>
              ) : (
                <>
                  {productSegmentView === 'cards' ? (
                    <S.GridCardsContainer>
                      {bgProducts?.products.map((product, i) => {
                        return (
                          <BgCard
                            data={product}
                            key={i}
                            handleSelectProduct={selectProductDispatch}
                          />
                        )
                      })}
                    </S.GridCardsContainer>
                  ) : (
                    <BgListTable
                      data={bgProducts?.products || []}
                      isLoading={isProductsLoading}
                      handleSelectProduct={selectProductDispatch}
                    />
                  )}

                  <S.PaginationContainer>
                    <CustomPagination
                      scrollToTop
                      page={productsFilters.page || 1}
                      pageSize={productsFilters.logs_per_page || 20}
                      totalItems={bgProducts?.total || 0}
                      totalPages={bgProducts?.total_pages || 0}
                      isLoading={isProductsLoading}
                      changePageValue={(page, pageSize) => handlePagination(page, pageSize)}
                    />
                  </S.PaginationContainer>
                </>
              )}
            </>
          )}
        </S.Container>
      </PageLayout>
    </>
  )
}
