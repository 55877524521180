import { api } from '@/services'
import { useCallback, useState } from 'react'
import { ProductExistsResponse } from '../types'

export const useProductExists = () => {
  const [isLoading, setIsLoading] = useState(false)

  const checkProductExists = useCallback(async (model: string, refId: string) => {
    setIsLoading(true)

    try {
      const { data } = await api.get<ProductExistsResponse>(
        `/products/pn_or_model_exists?ref_id=${refId}&model=${model}`,
      )

      return data
    } catch (err) {
      console.error('Error checking if product exists', err)
      return null
    } finally {
      setIsLoading(false)
    }
  }, [])

  return { checkProductExists, isLoading }
}
