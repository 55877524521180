import { useMutation } from '@tanstack/react-query'

import { api } from '@/services'

interface CreateSpecifications {
  field_type_id: number
  product_type_vtex_id: number
  field_group_id: number
  name: string
  position: number
}

export const CreateSpecificationsErrorMessages = new Map([['teste', 'teste']])

export const useCreateSpecificationsMutation = () => {
  return useMutation({
    mutationFn: async (data: CreateSpecifications) => {
      return api.post('/specifications', data)
    },
  })
}
