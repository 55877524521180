import { rem } from 'polished'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  .ant-form-item {
    margin-bottom: 5px;
  }

  .ant-input {
    height: ${rem(40)};
  }
`

export const InputColumns = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 24px;

  .ant-form-item {
    flex: 1;
  }
`

export const Label = styled.span`
  display: flex;
  align-items: center;
  gap: 4px;
  color: ${(props) => props.theme.customTokens.input.label};

  > span {
    padding-top: 2px;
    font-size: 10px;
  }
`
