import { useEffect } from 'react'

import { Form, FormInstance, Input, Select } from 'antd'

import * as S from './styles'

import { filtersRemap, specificationsRemap } from '@/common'
import { useProductTypeGroupsField, useProductTypes } from '@/features/BG'
import { useSpecificationsFieldTypes } from '@/features/specifications/queries'

export type SpecificationsFormData = {
  fieldType: number
  productType: number
  groupSpecification: number
  name: string
  position: number
}

interface SpecificationsFormProps {
  onSubmit: (data: SpecificationsFormData) => void
  isSubmitting: boolean
  form: FormInstance
}

const CreateSpecificationsForm = ({ onSubmit, isSubmitting, form }: SpecificationsFormProps) => {
  const selectedProductType = Form.useWatch('productType', form)
  const selectedGroupsSpecifications = Form.useWatch('groupSpecification', form)

  const { data: productTypes, isLoading: isProductTypeLoading } = useProductTypes({
    page: 1,
    per_page: 100,
  })

  const { data: specificationsFieldType, isLoading: isSpecificationsFieldTypeLoading } =
    useSpecificationsFieldTypes()

  const { data: groupSpecifications, isLoading: isGroupSpecificationsLoading } =
    useProductTypeGroupsField(selectedProductType, !!selectedProductType)

  const productTypeOptions = productTypes?.product_types?.map((value) => ({
    value: value.vtex_id,
    label: (filtersRemap.get(value.name) as string) || value.name,
  }))

  const specificationsFieldTypeOptions = specificationsFieldType?.map((value) => ({
    value: value.id,
    label: (specificationsRemap.get(value.name) as string) || value.name,
  }))

  const GroupSpecifications = groupSpecifications?.map((value) => ({
    value: value.id,
    label: value.name,
  }))

  useEffect(() => {
    const selectedGroupSpecification = groupSpecifications?.find(
      (category) => category.id === selectedGroupsSpecifications,
    )?.name

    if (selectedGroupSpecification === undefined) {
      return form.setFieldValue('groupSpecification', '')
    }
  }, [form, groupSpecifications, selectedGroupsSpecifications])

  return (
    <S.Form form={form} onFinish={onSubmit} disabled={isSubmitting} layout="vertical" requiredMark>
      <S.Row>
        <Form.Item
          name={'fieldType'}
          label={'Tipo de Campo'}
          rules={[{ required: true, message: 'Campo obrigatório' }]}
        >
          <Select
            size="large"
            loading={isSpecificationsFieldTypeLoading}
            options={specificationsFieldTypeOptions}
          />
        </Form.Item>

        <Form.Item
          name={'productType'}
          label={'Tipo de Produto'}
          rules={[{ required: true, message: 'Campo obrigatório' }]}
        >
          <Select size="large" loading={isProductTypeLoading} options={productTypeOptions} />
        </Form.Item>

        <Form.Item
          name={'groupSpecification'}
          label={'Grupo de Especificações'}
          rules={[{ required: true, message: 'Campo obrigatório' }]}
        >
          <Select
            size="large"
            disabled={!selectedProductType}
            loading={!!selectedProductType && isGroupSpecificationsLoading}
            options={GroupSpecifications}
          />
        </Form.Item>
      </S.Row>

      <S.Row>
        <Form.Item
          name={'name'}
          label={'Nome'}
          rules={[{ required: true, message: 'Campo obrigatório' }]}
        >
          <Input />
        </Form.Item>
      </S.Row>

      <S.Row>
        <Form.Item
          name={'position'}
          label={'Posição do Campo'}
          rules={[
            {
              required: true,
              message: 'Campo obrigatório',
            },
            {
              pattern: /[0-9]/,
              message: 'Este campo aceita apenas valores númericos!',
            },
            {
              min: 0,
              message: 'Este campo aceita apenas valores positivos!',
              validator: (_, value) => {
                if (Number(value) < 0) {
                  return Promise.reject('Este campo aceita apenas valores positivos!')
                }

                return Promise.resolve()
              },
            },
          ]}
        >
          <Input type="number" min={0} />
        </Form.Item>
      </S.Row>
    </S.Form>
  )
}

export { CreateSpecificationsForm }
