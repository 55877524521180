import { useMutation, useQueryClient } from '@tanstack/react-query'

import { notificationsKeys } from '../queries'

import { api } from '@/services'

export const useReadNotificationMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (notificationId: string) => {
      return api.patch(`/notifications/${notificationId}/mark_as_read`)
    },
    onMutate: async () => await queryClient.cancelQueries(notificationsKeys.all),
    onSettled: () => {
      queryClient.invalidateQueries(notificationsKeys.all)
      queryClient.invalidateQueries(notificationsKeys.list())
    },
  })
}
