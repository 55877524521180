import { Button, Form } from 'antd'
import { toast } from 'sonner'

import { EmailNotificationFormData, EmailNotifyForm } from '../../Form/EmailNotifyForm'
import * as S from './styles'

import { BaseModal } from '@/components'
import { Product, useEmailNotifyMutation } from '@/features/BG'

type EmailNotifyProps = {
  isOpen: boolean
  onClose: () => void
  bgModel: Product | undefined
}

export const EmailNotifyModal = ({ isOpen, onClose, bgModel }: EmailNotifyProps) => {
  const [form] = Form.useForm<{ email: string }>()
  const emailNotificationMutation = useEmailNotifyMutation()

  const handleNotification = ({ email }: EmailNotificationFormData) => {
    const promise = emailNotificationMutation.mutateAsync({
      email,
      product_id: bgModel?.id as string,
    })

    toast.promise(promise, {
      loading: 'Enviando notificação...',
      success: (
        <S.ToastContainer>
          <S.CircleOutlined />

          <div>
            Notificação <span style={{ fontWeight: 'bold' }}>enviada</span> com{' '}
            <span style={{ fontWeight: 'bold' }}>sucesso</span>!
          </div>
        </S.ToastContainer>
      ),
      className: 'toast-sucess',
      error: 'Erro ao enviar notificação!',
    })

    onClose()
  }

  return (
    <BaseModal
      open={isOpen}
      onCancel={onClose}
      title="Notificar Área Responsável"
      width={718}
      footer={
        <>
          <Button onClick={onClose} type="link">
            Cancelar
          </Button>
          <Button type="primary" htmlType="submit" onClick={() => form.submit()}>
            Notificar
          </Button>
        </>
      }
    >
      <S.Container>
        <S.Text>
          {`Por favor, insira o e-mail da área responsável para precificação do `}
          <strong>BG {bgModel?.ref_id || '--'}</strong>
        </S.Text>

        <EmailNotifyForm form={form} onSubmit={handleNotification} />
      </S.Container>
    </BaseModal>
  )
}
