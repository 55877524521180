import { useState } from 'react'

import { Form, FormInstance, Input, Select } from 'antd'

import * as S from './styles'

import { filtersRemap, useDebounce } from '@/common'
import { HelpTooltip } from '@/components'
import { useAnymarketCategories, useGlobalCategories, useProductTypes } from '@/features/BG/queries'

export type CategoriesFormData = {
  name: string
  similarWords: string
  pageTitle: string
  description: string
  globalCategory: number
  productType: number
  anymarket_id: number
}

interface CategoriesFormProps {
  onSubmit: (data: CategoriesFormData) => void
  isSubmitting: boolean
  form: FormInstance
}

const CategoriesForm = ({ onSubmit, isSubmitting, form }: CategoriesFormProps) => {
  const [globalCategoryName, setGlobalCategoryName] = useState('')
  const debouncedGlobalCategory = useDebounce(globalCategoryName, 500)

  const { data: productTypes, isLoading: isProductTypesLoading } = useProductTypes({
    page: 1,
    per_page: 100,
  })

  const {
    data: globalcategories,
    isLoading: isGlobalCategoriesLoading,
    isFetching,
  } = useGlobalCategories(debouncedGlobalCategory)

  const { data: anymarketCategories, isLoading: isAnymarketCategoriesLoading } =
    useAnymarketCategories()

  const productTypeOptions = productTypes?.product_types?.map((value) => ({
    value: value.vtex_id,
    label: (filtersRemap.get(value.name) as string) || value.name,
  }))

  const globalCategoriesOptions = globalcategories?.google_global_categories.map((value) => ({
    value: value.google_id,
    label: value.name,
  }))

  const anymarketCategoriesOptions = anymarketCategories?.map((category) => ({
    value: category.id,
    label: category.name,
  }))

  return (
    <S.Form form={form} onFinish={onSubmit} disabled={isSubmitting} layout="vertical" requiredMark>
      <Form.Item
        name={'name'}
        label={'Nome'}
        rules={[{ required: true, message: 'Campo obrigatório' }]}
      >
        <Input placeholder="ex: Jogos..." />
      </Form.Item>

      <S.Row>
        <Form.Item
          name={'pageTitle'}
          label={'Título da Página'}
          rules={[{ required: true, message: 'Campo obrigatório' }]}
        >
          <Input placeholder="Jogos de Pc" />
        </Form.Item>

        <Form.Item
          name={'similarWords'}
          label={
            <S.LabelContainer>
              <span>Palavras Similares</span>

              <HelpTooltip title="As palavras similares devem ser separadas usando vírgula." />
            </S.LabelContainer>
          }
          rules={[{ required: true, message: 'Campo obrigatório' }]}
        >
          <Input placeholder="alegre, feliz" />
        </Form.Item>
      </S.Row>

      <S.Row>
        <Form.Item
          name={'globalCategory'}
          label={'Categoria Global'}
          rules={[{ required: true, message: 'Campo obrigatório' }]}
        >
          <Select
            showSearch
            size="large"
            placeholder="Selecione a categoria global"
            options={globalCategoriesOptions}
            loading={isGlobalCategoriesLoading || isFetching}
            onSearch={setGlobalCategoryName}
            filterOption={false}
          />
        </Form.Item>

        <Form.Item
          name={'productType'}
          label={'Tipo de Produto'}
          rules={[{ required: true, message: 'Campo obrigatório' }]}
        >
          <Select
            size="large"
            placeholder="Selecione o tipo de produto"
            options={productTypeOptions || []}
            loading={isProductTypesLoading}
          />
        </Form.Item>

        <Form.Item
          name={'anymarket_id'}
          label={'Categoria AnyMarket'}
          rules={[{ required: true, message: 'Campo obrigatório' }]}
        >
          <Select
            size="large"
            placeholder="Selecione a categoria anymarket"
            options={anymarketCategoriesOptions || []}
            loading={isAnymarketCategoriesLoading}
          />
        </Form.Item>
      </S.Row>

      <Form.Item
        name={'description'}
        label={'Descrição'}
        rules={[{ required: true, message: 'Campo obrigatório' }]}
      >
        <Input.TextArea placeholder="Descrição da categoria..." style={{ height: 80 }} />
      </Form.Item>
    </S.Form>
  )
}

export { CategoriesForm }
