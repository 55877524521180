import { CheckCircleOutlined, EditOutlined } from '@ant-design/icons'
import { Typography } from 'antd'
import { rem } from 'polished'
import styled from 'styled-components'

export const Container = styled.div`
  .ant-tabs-tab {
    font-size: ${rem(16)} !important;
  }

  .ant-tabs-nav {
    padding-left: 24px;
  }

  .ant-tabs-ink-bar {
    height: 2px !important;
  }

  .ant-typography {
    line-height: initial;
  }
`

export const SwitchTitleArea = styled.div`
  display: flex;
  gap: 8px;
`

export const UserContent = styled.div`
  display: flex;
  align-items: center;
  gap: 22px;

  padding: 22px;
  background-color: #f8f8f8;
`

export const NameArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  strong {
    color: #434343;
    font-size: 16px;
    line-height: initial;
  }
`

export const Description = styled(Typography.Text)`
  color: #6c6c6c;
`

export const ExtraInfoContent = styled.div`
  display: flex;
  gap: 46px;
  /* justify-content: end; */
  padding: 15px 24px 65px 130px;
`

export const EditIcon = styled(EditOutlined)`
  position: absolute;
  top: 80px;
  left: 84px;

  border-radius: 50%;
  padding: 6px;
  font-size: 18px;
  color: #fff;
  background-color: #80c343;
`

export const PasswordContainer = styled.div`
  display: grid;
  gap: 18px;
  padding: 39px 0px 143px 23px;

  border-top: 1px solid rgba(0, 0, 0, 0.06);
`

export const PasswordText = styled(Typography.Text)`
  font-size: 14px;
  color: #6c6c6c;
`

export const Password = styled.p`
  font-size: 16px;
  color: #434343;
  padding-left: 12px;
`

export const AvatarArea = styled.div``

export const ToastContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

export const CircleOutlined = styled(CheckCircleOutlined)`
  display: flex;
  align-items: center;
  justify-content: center;

  color: #fff;
  width: 14px;
  height: 14px;
  border-radius: 99%;
  background-color: #80c343;
`
