import { useEffect } from 'react'

import { Button, Form } from 'antd'
import { toast } from 'sonner'

import { EditCategoriesForm, EditCategoriesFormData } from '../../Form'
import * as S from './styles'

import { ApiError, BaseModalProps, filtersRemap, toastError, useToggle } from '@/common'
import { BaseModal } from '@/components'
import { editCategoriesErrorMessages, useEditCategoriesMutation } from '@/features/BG/mutations'
import { CategoryType } from '@/features/BG/types'

interface CategoriesModalProps extends BaseModalProps {
  data: CategoryType
}

const CategoriesModal = ({ isOpen, onClose, data }: CategoriesModalProps) => {
  const [form] = Form.useForm()

  const [isEditing, toggleEdit] = useToggle(false)
  const editCategoriesMutation = useEditCategoriesMutation()

  useEffect(() => {
    if (data) {
      form.setFieldsValue(data)
      form.setFieldValue('global_category_id', {
        label: data.global_category_name,
        value: data.global_category_id,
      })
    }
  }, [form, data])

  async function handleEditCategories(values: EditCategoriesFormData) {
    const payload = {
      id: data.id,
      name: values.name,
      keywords: values.keywords,
      title: values.title,
      description: values.description,
      google_global_category_id: values.global_category_id.value,
      anymarket_id: values.anymarket_id,
    }

    const promise = editCategoriesMutation.mutateAsync(payload)

    toast.promise(promise, {
      loading: 'Editando categoria...',
      success: () => {
        onClose()

        return (
          <S.ToastContainer>
            <S.CircleOutlined />

            <div>
              Categoria <span style={{ fontWeight: 'bold' }}>editada</span> com{' '}
              <span style={{ fontWeight: 'bold' }}>sucesso</span>!
            </div>
          </S.ToastContainer>
        )
      },
      className: 'toast-sucess',
      error: (error: ApiError) =>
        toastError({
          error,
          errorMessages: editCategoriesErrorMessages,
          defaultMessage: 'Erro ao editar categoria.',
        }),
    })
  }

  const CategoriesView = () => (
    <>
      <S.FieldRow>
        <S.CategoryData>
          <strong>Nome</strong>

          <span>{data.name}</span>
        </S.CategoryData>
      </S.FieldRow>

      <S.FieldRow>
        <S.CategoryData>
          <strong>Título da Página</strong>

          <span>{data.title}</span>
        </S.CategoryData>

        <S.CategoryData>
          <strong>Palavras Similares</strong>

          <span>{data.keywords}</span>
        </S.CategoryData>
      </S.FieldRow>

      <S.FieldRow>
        <S.CategoryData>
          <strong>Categoria Global</strong>

          <span>{data.global_category_name}</span>
        </S.CategoryData>

        <S.CategoryData>
          <strong>Tipo de Produto</strong>

          <span>
            {(filtersRemap.get(data.product_type.name) as string) || data.product_type.name}
          </span>
        </S.CategoryData>

        <S.CategoryData>
          <strong>Categoria Anymarket</strong>

          <span>{data?.anymarket_name || '--'}</span>
        </S.CategoryData>
      </S.FieldRow>

      <S.FieldRow>
        <S.CategoryData>
          <strong>Descrição</strong>

          <span>{data.description}</span>
        </S.CategoryData>
      </S.FieldRow>
    </>
  )

  return (
    <BaseModal
      open={isOpen}
      onCancel={onClose}
      title="Dados cadastrais"
      width={740}
      footer={
        <>
          {!isEditing ? (
            <Button
              onClick={onClose}
              type="link"
              htmlType="button"
              disabled={editCategoriesMutation.isLoading}
            >
              Cancelar
            </Button>
          ) : (
            <Button type="link" onClick={toggleEdit}>
              Voltar
            </Button>
          )}

          {!isEditing ? (
            <>
              <Button type="primary" onClick={toggleEdit}>
                Editar
              </Button>
            </>
          ) : (
            <Button
              type="primary"
              htmlType="submit"
              onClick={() => form.submit()}
              disabled={editCategoriesMutation.isLoading}
            >
              Salvar
            </Button>
          )}
        </>
      }
    >
      <S.Container>
        {!isEditing ? (
          <CategoriesView />
        ) : (
          <EditCategoriesForm
            form={form}
            onEdit={handleEditCategories}
            isLoading={editCategoriesMutation.isLoading}
          />
        )}
      </S.Container>
    </BaseModal>
  )
}

export { CategoriesModal }
