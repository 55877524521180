import { Button as AntdButton, Input, Divider as AntDivider } from 'antd'
import { rem } from 'polished'
import styled from 'styled-components'

export const FormBox = styled.div`
  .ant-form {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .ant-input {
    text-align: start;

    border: 1px solid ${(theme) => theme.theme.colors.colorBorderPrimary};
    padding: 10px;
    margin-top: 0;
    margin-bottom: 0;
    border-radius: 4px;
  }

  .ant-form-item {
    margin-bottom: 0;
  }
`

export const InputPassword = styled(Input.Password)`
  height: ${rem(40)};
  text-align: center;

  border: 1px solid ${(theme) => theme.theme.colors.colorBorderPrimary};
  margin-top: 0;
  margin-bottom: 0;
  border-radius: 4px;
`

export const InputBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  @media screen and (max-height: 1020px) {
    max-height: 35vh;
    overflow: auto;
  }
`

export const Divider = styled(AntDivider)`
  border-bottom: 0.8px solid ${(theme) => theme.theme.colors.colorBorderPrimary};
  margin: 0;
`
export const DividerContainer = styled.div`
  width: ${rem(360)};
  margin: 0px;
  padding: 0px;
`
export const TextError = styled.span`
  margin-top: 0;
  padding-top: 0;
  color: ${(theme) => theme.theme.colors.error};
`
export const Button = styled(AntdButton)`
  height: ${rem(40)};
  font-size: ${rem(14)};
  border-radius: 4px;
  border: 0;

  color: ${(theme) => theme.theme.colors.white};
  background-color: ${(theme) => theme.theme.colors.brand};
`
