import { Steps } from 'antd'
import dayjs from 'dayjs'

import * as S from './styles'

import { statusRemap } from '@/common'
import { ProductStatus, Timeline } from '@/features/BG'

type CustomDotProps = {
  itemStatus?: string
  children: React.ReactNode
}

type BgTimelineProps = {
  timelineData?: Timeline[]
}

const BgTimeline = ({ timelineData }: BgTimelineProps) => {
  const Items = timelineData?.map((item, i) => ({
    description: (
      <S.ItemsContainer key={i}>
        <S.DescriptionName>{statusRemap.get(item.status as ProductStatus)}</S.DescriptionName>
        <S.DescriptionText>{item.user_id}</S.DescriptionText>
        <S.DescriptionDate>{dayjs(item.date).format('DD/MM/YYYY')}</S.DescriptionDate>
        <S.DescriptionText>{dayjs(item.date).format('HH:mm:ss')}</S.DescriptionText>
      </S.ItemsContainer>
    ),
  }))

  const CustomDot = ({ itemStatus, children }: CustomDotProps) => {
    return <S.DotDiv $status={itemStatus}>{children}</S.DotDiv>
  }

  return (
    <S.StepsContainer>
      <S.Content>
        <Steps
          initial={1}
          items={Items}
          style={{ marginBottom: 10 }}
          progressDot={(dot, { index }) => {
            const itemStatus = timelineData?.[index - 1].status

            return <CustomDot itemStatus={itemStatus}>{dot}</CustomDot>
          }}
        />
      </S.Content>
    </S.StepsContainer>
  )
}

export { BgTimeline }
