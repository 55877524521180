import styled from 'styled-components'

export const FormBox = styled.div`
  padding: 39px 148px 133px 23px;

  border-top: 1px solid rgba(0, 0, 0, 0.06);

  .ant-form-vertical .ant-form-item-label > label {
    color: #6c6c6c;
  }

  .ant-input-affix-wrapper .anticon.ant-input-password-icon {
    color: #5ea320;
  }
`

export const FormContainer = styled.div`
  display: flex;
  gap: 24px;
`

export const Password = styled.p`
  font-size: 16px;
  color: #434343;
  padding-left: 12px;
`
