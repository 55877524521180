import { DownOutlined, UpOutlined } from '@ant-design/icons'
import { Collapse, CollapseProps, Form, Skeleton, TabsProps } from 'antd'

import * as S from './styles'

import {
  BGDataForm,
  CheckboxFilter,
  separateFiltersAndIcons,
  useProductFilterAndIcons,
} from '@/features/BG'

type Icon = {
  name: string
  values: string[]
}

type Filter = {
  name: string
  values: string[]
}

interface BGDataProps {
  currentSelectedIcons?: Icon[]
  currentSelectedFilters?: Filter[]
  initialValues?: {
    filters?: Filter[]
    icons?: Icon[]
  }
  productType: {
    vtex_id: number | undefined
    name: string | undefined
  }
  vtexCategoryId: number
}

export function BGData({
  currentSelectedIcons,
  currentSelectedFilters,
  initialValues,
  productType,
  vtexCategoryId,
}: BGDataProps) {
  const form = Form.useFormInstance()

  const { data: filtersAndIcons, isLoading, isError } = useProductFilterAndIcons(vtexCategoryId)

  const { filters, icons } = separateFiltersAndIcons(filtersAndIcons)

  const filterCollapseItems: CollapseProps['items'] = filters?.map((data) => {
    // const filterOptions = data?.filters?.map((filter) => filter.value)
    const filterOptions = data?.values?.map((filter) => filter.value)

    const hasInitialValues = initialValues?.filters && initialValues?.filters?.length > 0

    let selected: string[] = []

    if (hasInitialValues) {
      initialValues?.filters?.map((filter) => {
        if (filter.name === data.name) {
          selected = filter.values
        }
      })
    }

    if (currentSelectedFilters !== undefined) {
      currentSelectedFilters.map((filter) => {
        if (filter.name === data.name) {
          selected = filter.values
        }
      })
    }

    return {
      key: data.field_id,
      label: data.name,
      children: (
        <CheckboxFilter
          originalData={filterOptions}
          selectedOptions={currentSelectedFilters !== undefined || hasInitialValues ? selected : []}
          onChange={(e) => {
            const filterValues = form.getFieldValue('filters')

            const items = filterValues?.filter((filter: any) => filter.name !== data.name)

            form.setFieldValue('filters', [
              ...items,
              { name: `${data.name}`, values: e as string[], field_id: data.field_id },
            ])
          }}
        />
      ),
    }
  })

  const iconCollapseItems: CollapseProps['items'] = icons?.map((data) => {
    const iconOptions = data?.values?.map((icon) => icon.value)

    const hasInitialValues = initialValues?.icons && initialValues?.icons?.length > 0

    let selected: string[] = []

    if (hasInitialValues) {
      initialValues?.icons?.map((icon) => {
        if (icon.name === data.name) {
          selected = icon.values
        }
      })
    }

    if (currentSelectedIcons !== undefined) {
      currentSelectedIcons.map((icon) => {
        if (icon.name === data.name) {
          selected = icon.values
        }
      })
    }

    return {
      key: data.field_id,
      label: data.name,
      children: (
        <CheckboxFilter
          originalData={iconOptions}
          selectedOptions={currentSelectedIcons !== undefined || hasInitialValues ? selected : []}
          onChange={(e) => {
            const iconValues = form.getFieldValue('icons')

            const items = iconValues?.filter((icon: any) => icon.name !== data.name)

            form.setFieldValue('icons', [
              ...items,
              { name: `${data.name}`, values: e as string[], field_id: data.field_id },
            ])
          }}
        />
      ),
    }
  })

  const tabItems: TabsProps['items'] = [
    {
      key: '1',
      label: 'FILTROS',
      children: isLoading ? (
        <Skeleton active />
      ) : isError || !filters || filters?.length <= 0 ? (
        <span>Sem filtros definidos</span>
      ) : (
        <Collapse
          expandIcon={({ isActive }) => (isActive ? <UpOutlined /> : <DownOutlined />)}
          accordion
          defaultActiveKey={'1'}
          expandIconPosition="start"
          bordered={false}
          style={{ background: 'transparent' }}
          items={filterCollapseItems || []}
        />
      ),
    },
    {
      key: '2',
      label: 'ICONES',
      children: isLoading ? (
        <Skeleton active />
      ) : isError || !icons || icons?.length <= 0 ? (
        <span>Sem icones definidos</span>
      ) : (
        <Collapse
          expandIcon={({ isActive }) => (isActive ? <UpOutlined /> : <DownOutlined />)}
          accordion
          defaultActiveKey={'1'}
          expandIconPosition="start"
          bordered={false}
          style={{ background: 'transparent' }}
          items={iconCollapseItems || []}
        />
      ),
    },
  ]

  return (
    <>
      <BGDataForm productType={productType} />

      <S.TabsTitle>Detalhes do BG</S.TabsTitle>

      <S.DataTabsContainer>
        <S.StyledTabs defaultActiveKey="1" centered items={tabItems} />
      </S.DataTabsContainer>
    </>
  )
}
