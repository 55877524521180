import { useMutation, useQueryClient } from '@tanstack/react-query'

import { specificationKeys } from '../../queries'
import { SpecificationType } from '../../types'

import { api } from '@/services'

interface ChangeSpecificationVisibilityMutation {
  specification_id: string
  status: boolean
}

export const useChangeSpecificationVisibilityMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ specification_id, status }: ChangeSpecificationVisibilityMutation) => {
      return api.patch(`specifications/${specification_id}/visibility`, {
        is_active: status,
      })
    },
    onMutate: async (editedSpecification) => {
      await queryClient.cancelQueries(specificationKeys.all)

      const previousSpecifications = queryClient.getQueryData<SpecificationType[]>(
        specificationKeys.lists(),
      )

      if (previousSpecifications) {
        queryClient.setQueryData<SpecificationType[]>(
          specificationKeys.lists(),
          (specificationList) => {
            return specificationList?.map((specification) => {
              if (specification.id === editedSpecification.specification_id) {
                return {
                  ...specification,
                  is_active: !specification.is_active,
                }
              }

              return specification
            })
          },
        )
      }

      return previousSpecifications
    },

    onError: (error, _, previousSpecifications) => {
      console.log(error)

      if (previousSpecifications) {
        queryClient.setQueryData(specificationKeys.lists(), previousSpecifications)
      }
    },
    onSettled: () => queryClient.invalidateQueries(specificationKeys.all),
  })
}
