import { Divider, Image, Skeleton } from 'antd'
import DOMPurify from 'dompurify'

import { BgStatusTags, BgTimeline } from '../..'
import * as S from './styles'

import { BaseModalProps, imgFallback } from '@/common'
import { BaseModal } from '@/components'
import { formatProductType, hasHeroImage, useProductDetails, useTimeline } from '@/features/BG'

interface BgHistoricModalProps extends BaseModalProps {
  productId: string
}

const BgHistoricModal = ({ isOpen, onClose, productId }: BgHistoricModalProps) => {
  const { data } = useTimeline(productId)
  const { data: product, isLoading } = useProductDetails(productId)

  const SkeletonPage = () => {
    return (
      <S.SkeletonContainer>
        <S.Content>
          <Skeleton.Input active style={{ height: 180, width: 180 }} />

          <S.InfoContainer>
            <S.StatusContainer>
              <Skeleton.Input active />
            </S.StatusContainer>

            <S.SummaryContainer>
              <S.DisplayName>
                <Skeleton.Input active block />
              </S.DisplayName>

              <S.Summary>
                <Skeleton.Input active style={{ height: 200 }} block />
              </S.Summary>
            </S.SummaryContainer>
          </S.InfoContainer>
        </S.Content>

        {/* <Skeleton.Input active block /> */}
      </S.SkeletonContainer>
    )
  }

  return (
    <BaseModal open={isOpen} onCancel={onClose} title={'Histórico do BG'} width={917} footer={null}>
      {isLoading ? (
        <SkeletonPage />
      ) : (
        <S.Container>
          <S.Content>
            <S.ImageContainer>
              <Image
                src={
                  hasHeroImage(product?.status)
                    ? product?.partner_files?.hero_image_partner_url
                    : product?.sku?.sku_files?.[0]?.filename_url
                }
                fallback={imgFallback}
                preview={false}
                height={180}
                width={180}
                style={{ objectFit: 'contain', borderRadius: 6, height: 180, width: 180 }}
              />
            </S.ImageContainer>

            <S.InfoContainer>
              <S.SummaryContainer>
                <S.StatusContainer>
                  <BgStatusTags status={product?.status} />
                </S.StatusContainer>

                <S.ProductCategory>
                  {formatProductType(product?.product_type_name as string) || '--'}

                  <Divider type="vertical" />

                  <strong>{product?.category_name}</strong>
                </S.ProductCategory>

                <S.DisplayName>{product?.model}</S.DisplayName>

                <S.Summary>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(
                        String(product?.overview === null ? '-' : product?.overview),
                      ),
                    }}
                  />
                </S.Summary>
              </S.SummaryContainer>
            </S.InfoContainer>
          </S.Content>

          {data ? <BgTimeline timelineData={data} /> : <span>Historico indisponivel</span>}
        </S.Container>
      )}
    </BaseModal>
  )
}

export { BgHistoricModal }
