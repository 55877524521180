import { useMutation } from '@tanstack/react-query'

import { ApiError } from '@/common'
import { api } from '@/services'

interface EmailNotifyMutation {
  email: string
  product_id: string
}

export const useEmailNotifyMutation = () => {
  return useMutation({
    mutationFn: async ({ email, product_id }: EmailNotifyMutation) => {
      return api.post(`/email`, {
        to_address: email,
        product_id,
      })
    },

    onError: (error: ApiError) => {
      console.error(error)
    },
  })
}
