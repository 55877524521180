import { CameraOutlined, EditOutlined } from '@ant-design/icons'
import { Avatar, Button, Typography } from 'antd'
import styled from 'styled-components'

export const FieldRow = styled.div`
  display: flex;
  align-items: center;
  gap: 22px;

  padding: 22px;
  background-color: #f8f8f8;

  .ant-form-item {
    margin-bottom: 0;
  }

  .ant-typography {
    line-height: initial;
  }

  .fullname {
    flex: 1;
  }
`

export const SecondFieldRow = styled.div`
  display: flex;
  justify-content: end;

  gap: 40px;
  padding: 15px 0px 50px 130px;

  .ant-form-item {
    flex: 1;
    margin-bottom: 0;
  }

  strong {
    color: rgba(67, 67, 67, 0.5);
    font-size: 16px;
    line-height: initial;
    cursor: not-allowed;
  }
`

export const Description = styled(Typography.Text)`
  color: #6c6c6c;

  span {
    font-size: 10px;
  }
`

export const EditIcon = styled(EditOutlined)`
  position: absolute;
  top: 86px;
  left: 82px;

  border-radius: 50%;
  padding: 4px;
  font-size: 16px;
  color: #fff;
  background-color: #80c343;
`

export const UploadContent = styled.div`
  display: inline-block;
  position: relative;
`

export const ImageMask = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
`

export const UploadButton = styled(Button)`
  position: absolute;
  top: 25%;
  left: 25%;
  width: 50%;
  height: 50%;
  border-radius: 50%;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: rgba(255, 255, 255, 0.1);

  &.ant-btn-text:not(:disabled):not(.ant-btn-disabled):hover {
    background: rgba(255, 255, 255, 0.3);
  }
`

export const PhotoIcon = styled(CameraOutlined)`
  color: #fff;
  font-size: 20px;
  cursor: pointer;
`

export const RemoveAvatarButton = styled(Button)`
  position: absolute;
  bottom: -5px;
  right: -5px;
  border-radius: 50%;

  &:disabled {
    display: none;
  }
`

export const AvatarNull = styled(Avatar)`
  background-color: #80c343;
  cursor: pointer;
  transition: all 0.2s ease;
`
