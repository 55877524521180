import { useMutation, useQueryClient } from '@tanstack/react-query'

import { productKeys } from '../queries'

import { api } from '@/services'

interface ProductDiscontinuedMutation {
  productId: string
  description: string
}

export const useProductDiscontinuedMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ productId, description }: ProductDiscontinuedMutation) => {
      return api.patch(`/products/${productId}/discontinue`, {
        description: description,
      })
    },
    onSettled: (_, __, { productId }) =>
      queryClient.invalidateQueries(productKeys.detail(productId)),
  })
}
