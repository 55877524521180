import { useMutation, useQueryClient } from '@tanstack/react-query'

import { categoryKeys } from '../../queries'
import { CategoryType } from '../../types'

import { api } from '@/services'

interface ChangeCategoryVisibilityMutation {
  category_id: string
  status: boolean
}

export const useChangeCategoryVisibiltyMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ category_id, status }: ChangeCategoryVisibilityMutation) => {
      return api.patch(`/categories/${category_id}/visibility`, {
        is_active: status,
      })
    },
    onMutate: async (editedCategory) => {
      await queryClient.cancelQueries(categoryKeys.all)

      const previousCategories = queryClient.getQueryData<CategoryType[]>(categoryKeys.lists())

      if (previousCategories) {
        queryClient.setQueryData<CategoryType[]>(categoryKeys.lists(), (categoryList) => {
          return categoryList?.map((category) => {
            if (category.id === editedCategory.category_id) {
              return {
                ...category,
                is_active: !category.is_active,
              }
            }

            return category
          })
        })
      }

      return previousCategories
    },
    onError: (error, _, previousCategories) => {
      console.log(error)

      if (previousCategories) {
        queryClient.setQueryData(categoryKeys.lists(), previousCategories)
      }
    },
    onSettled: () => queryClient.invalidateQueries(categoryKeys.all),
  })
}
