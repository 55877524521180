import { Form as AntForm } from 'antd'
import { rem } from 'polished'
import styled from 'styled-components'

export const Form = styled(AntForm)`
  gap: 0px;

  .ant-form-item {
    margin: 0px;
    padding: 0px;
  }

  .ant-checkbox-group {
    display: flex;
    flex-direction: column;
  }

  .ant-collapse {
    border: none;
    background-color: transparent;
  }

  .ant-collapse-header {
    border: none;
    background-color: transparent;
    color: ${(props) => props.theme.colors.black};
    font-weight: bold;
    font-size: ${rem(16)};
  }

  .ant-collapse-expand-icon {
    color: ${(props) => props.theme.colors.darkGreen};
  }
`
