import { Form, FormInstance, Input } from 'antd'

export type EmailNotificationFormData = {
  email: string
}

type EmailNotifyFormProps = {
  form: FormInstance
  onSubmit: (data: EmailNotificationFormData) => void
}

export const EmailNotifyForm = ({ form, onSubmit }: EmailNotifyFormProps) => {
  return (
    <Form onFinish={onSubmit} layout="vertical" form={form} requiredMark="optional">
      <Form.Item
        name="email"
        label="E-mail"
        rules={[
          { required: true, message: 'Campo obrigatório' },
          { type: 'email', message: 'Insira um e-mail válido' },
        ]}
      >
        <Input type="email" data-testid="notify-email-input" placeholder="Ex: john-doe@email.com" />
      </Form.Item>
    </Form>
  )
}
