import { FileWordOutlined } from '@ant-design/icons'
import { rem } from 'polished'
import styled from 'styled-components'

export const InputsRowContainer = styled.div`
  display: flex;
  align-items: flex-start;

  width: 100%;
  height: fit-content;

  .ant-select {
    width: ${rem(200)};
    height: ${rem(40)};
  }

  .ant-select .ant-select-arrow {
    color: ${({ theme }) => theme.colors.brand};
  }
`

export const Label = styled.span`
  display: flex;
  align-items: center;
  gap: 4px;
  color: ${(props) => props.theme.customTokens.input.label};

  > span {
    font-size: 10px;
  }
`

export const UploadStatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
`

export const FileIcon = styled(FileWordOutlined)`
  color: ${({ theme }) => theme.colors.brand};
  font-size: 48px;
`

export const FileName = styled.span``
