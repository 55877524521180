import { Button as AntdButton } from 'antd'
import { rem } from 'polished'
import styled from 'styled-components'

export const FormBox = styled.div`
  .ant-form {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .ant-input {
    width: none;
    height: ${rem(40)};
    text-align: start;

    border: 1px solid ${(theme) => theme.theme.colors.colorBorderPrimary};
    margin-top: 0;
    margin-bottom: 0;
    border-radius: 4px;
  }

  .ant-form-item {
    margin-bottom: 0;
  }
`

export const Button = styled(AntdButton)`
  width: auto;
  height: ${rem(40)};
  border-radius: 4px;
  font-size: ${rem(14)};
  background-color: ${(theme) => theme.theme.colors.brand};
  color: ${(theme) => theme.theme.colors.white};
  border: 0;
`
