import { LockOutlined, UserOutlined } from '@ant-design/icons'
import { Button as ButtonAntd, Input as InputAntd } from 'antd'
import { rem } from 'polished'
import styled from 'styled-components'

export const FormBox = styled.div`
  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .ant-form-item {
    margin: 0;
  }
`

export const InputsField = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media only screen and (max-width: 400px) {
    width: 200px;
  }
`

export const Input = styled(InputAntd)`
  height: ${rem(40)};
  border-radius: 4px;
  margin: 0;
`

export const InputPassword = styled(InputAntd.Password)`
  height: ${rem(40)};
  border-radius: 4px;
  margin: 0;
`

export const EmailInputIcon = styled(UserOutlined)`
  color: ${({ theme }) => theme.colors.darkGreen};
`

export const PasswordInputIcon = styled(LockOutlined)`
  color: ${({ theme }) => theme.colors.darkGreen};
`

export const ButtonsField = styled.div`
  display: flex;
  align-items: center;
  gap: 4rem;

  @media only screen and (max-width: 400px) {
    flex-direction: column;
    gap: 0.5rem;
  }
`

export const Button = styled(ButtonAntd)`
  width: ${rem(125)};
  height: ${rem(40)};
  border-radius: 4px;
  border: none;
  color: ${({ theme }) => theme.base.colorWhite};
  background-color: ${({ theme }) => theme.base.colorPrimary};
  &:hover {
    background-color: ${({ theme }) => theme.base.colorLinkActive};
  }
`

export const TextButton = styled(ButtonAntd)`
  font-size: ${rem(16)};
  color: ${({ theme }) => theme.colors.darkGreen};
  border: none;
  .ant-btn-link:hover {
    background-color: ${({ theme }) => theme.base.colorLinkActive};
  }
`

export const ErrorMessage = styled.span`
  display: flex;
  color: red;
  margin-bottom: 10px;
`
