import { QueryFunctionContext, useQuery } from '@tanstack/react-query'

import { productKeys } from '..'

import { Product } from '../..'

import { api } from '@/services'

async function fetchBGProductDetails({
  queryKey,
}: QueryFunctionContext<ReturnType<(typeof productKeys)['detail']>>) {
  const [{ bgId }] = queryKey

  const { data } = await api.get<Product>(`/products/${bgId}`)

  return data
}

export const useProductDetails = (id: string, enabled = true) => {
  return useQuery({
    queryKey: productKeys.detail(id),
    queryFn: fetchBGProductDetails,
    keepPreviousData: true,
    enabled: enabled,
  })
}
