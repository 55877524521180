import { useMutation, useQueryClient } from '@tanstack/react-query'

import { notificationsKeys } from '../queries'

import { api } from '@/services'

export const useClearAllNotificationsMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async () => {
      return api.patch('/notifications/mark_all_as_read')
    },
    onSettled: () => {
      queryClient.invalidateQueries(notificationsKeys.all)
      queryClient.invalidateQueries(notificationsKeys.list())
    },
  })
}
