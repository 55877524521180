import { Tag } from 'antd'
import { rem } from 'polished'
import styled from 'styled-components'

interface BgTagsProps {
  $isBgView?: boolean
  colorRed?: boolean
}

export const StyledTag = styled(Tag)<BgTagsProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  height: ${({ $isBgView }) => ($isBgView ? `${rem(27)}` : `${rem(21)}`)};
  width: fit-content;

  border-radius: 4px;
  font-size: ${({ $isBgView }) => ($isBgView ? `${rem(14)}` : `${rem(12)}`)};
  margin-top: ${({ $isBgView }) => ($isBgView ? null : 'auto')};
`

export const SketchTag = styled(StyledTag)`
  color: #945fb9;
  border: 1px solid rgba(148, 95, 185, 0.5);
  background-color: rgba(148, 95, 185, 0.1);
`

export const MarketingTag = styled(StyledTag)`
  color: #db9200;
  border: 1px solid rgba(250, 173, 20, 0.5);
  background-color: rgba(250, 173, 20, 0.1);
`
export const AgencyTag = styled(StyledTag)`
  color: #ea7b26;
  border: 1px solid rgba(234, 123, 38, 0.5);
  background-color: rgba(234, 123, 38, 0.1);
`
export const ProtheusTag = styled(StyledTag)`
  color: #e86452;
  border: 1px solid rgba(232, 100, 82, 0.5);
  background-color: rgba(232, 100, 82, 0.1);
`
export const SketchEcommerceTag = styled(StyledTag)`
  color: #4a91fd;
  border: 1px solid rgba(74, 145, 253, 0.5);
  background-color: rgba(74, 145, 253, 0.1);
`
export const PrecificationTag = styled(StyledTag)`
  color: #004fc7;
  border: 1px solid rgba(0, 79, 199, 0.5);
  background-color: rgba(0, 79, 199, 0.1);
`
export const PublishedTag = styled(StyledTag)`
  color: #1e9493;
  border: 1px solid rgba(30, 148, 147, 0.5);
  background-color: rgba(30, 148, 147, 0.1);
`
export const UnpublishedTag = styled(StyledTag)`
  color: #666666;
  border: 1px solid rgba(153, 153, 153, 0.5);
  background-color: rgba(250, 250, 250, 1);
`
export const DiscontinuedTag = styled(StyledTag)`
  color: ${({ theme }) => theme.colors.lightGray};
  border: 1px solid ${({ theme }) => theme.colors.lightGray};
  background-color: ${({ theme }) => theme.colors.white};
`
