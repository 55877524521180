import { useState } from 'react'

import { Form, FormInstance, Input, Select } from 'antd'

import * as S from './styles'

import { useDebounce } from '@/common'
import { useGlobalCategories } from '@/features/BG/queries'

export type EditProductTypesFormData = {
  name: string
  global_category_id: number
}

interface EditProductTypesFormProps {
  onEdit: (data: EditProductTypesFormData) => void
  form: FormInstance
  isLoading: boolean
}

const EditProductTypeModal = ({ form, onEdit, isLoading }: EditProductTypesFormProps) => {
  const [globalCategoryName, setGlobalCategoryName] = useState('')
  const debouncedGlobalCategory = useDebounce(globalCategoryName, 500)

  const {
    data: globalcategories,
    isLoading: isGlobalCategoriesLoading,
    isFetching: isGlobalCategoriesFetching,
  } = useGlobalCategories(debouncedGlobalCategory)

  const globalCategoriesOptions = globalcategories?.google_global_categories.map((value) => ({
    value: value.google_id,
    label: value.name,
  }))

  return (
    <S.Form
      form={form}
      onFinish={onEdit}
      disabled={isLoading}
      layout="vertical"
      requiredMark={false}
    >
      <S.Row>
        <Form.Item
          name={'name'}
          label={'Nome'}
          rules={[{ required: true, message: 'Campo obrigatório' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name={'global_category_id'}
          label={'Categoria Global'}
          rules={[{ required: true, message: 'Campo obrigatório' }]}
        >
          <Select
            size="large"
            showSearch
            placeholder="Selecione a categoria"
            options={globalCategoriesOptions}
            loading={isGlobalCategoriesLoading || isGlobalCategoriesFetching}
            onSearch={setGlobalCategoryName}
            filterOption={false}
          />
        </Form.Item>
      </S.Row>
    </S.Form>
  )
}

export { EditProductTypeModal }
