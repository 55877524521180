import { QueryFunctionContext, useQuery } from '@tanstack/react-query'

import { ProductTemplate } from '../../../types'
import { templateKeys } from '../../query-keys'

import { api } from '@/services'

async function fetchProductTemplate({
  queryKey,
}: QueryFunctionContext<ReturnType<(typeof templateKeys)['detail']>>) {
  const [{ model }] = queryKey

  const { data } = await api.get<ProductTemplate>(`/templates/${model}`)

  return data
}

export const useProductTypeTemplate = (model: string, enabled = true) => {
  return useQuery({
    queryKey: templateKeys.detail(model),
    queryFn: fetchProductTemplate,
    keepPreviousData: true,
    enabled: enabled,
  })
}
