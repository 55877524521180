import styled from 'styled-components'

export const FieldRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 28px;

  .ant-form-item {
    flex: 1;
  }
`

export const Label = styled.span`
  display: flex;
  align-items: center;
  gap: 4px;
  color: ${(props) => props.theme.customTokens.input.label};

  > span {
    padding-top: 2px;
    font-size: 10px;
  }
`
