import { QueryFunctionContext, useQuery } from '@tanstack/react-query'

import { SpecificationsListResponse } from '../../types'
import { specificationKeys } from '../query-keys'

import { SpecificationFilters } from '@/app'
import { generateSearchParams } from '@/common'
import { api } from '@/services'

async function fetchSpecifications({
  queryKey,
}: QueryFunctionContext<ReturnType<(typeof specificationKeys)['list']>>) {
  const [{ filters }] = queryKey

  const { page, per_page, ...activeFilters } = filters

  const params = generateSearchParams(activeFilters)

  const { data } = await api.get<SpecificationsListResponse>(
    `/specifications?per_page=${per_page}&page=${page}&${params}`,
  )

  return data
}

export const useSpecifications = (
  filters: SpecificationFilters = {
    page: 1,
    per_page: 20,
  },
  enabled = true,
) => {
  return useQuery({
    queryKey: specificationKeys.list(filters),
    queryFn: fetchSpecifications,
    keepPreviousData: true,
    enabled,
  })
}
