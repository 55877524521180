import { QueryFunctionContext, useQuery } from '@tanstack/react-query'

import { productKeys } from '../../query-keys'

import { api } from '@/services'

async function fetchProductTypeEmptyTemplate({
  queryKey,
}: QueryFunctionContext<ReturnType<(typeof productKeys)['typeDetail']>>) {
  const [{ vtexId }] = queryKey

  const { data } = await api.get<Record<string, string>>(`/product_types/${vtexId}/empty_template`)

  return data
}

export const useProductTypeEmptyTemplate = (vtexId: number, enabled = true) => {
  return useQuery({
    queryKey: productKeys.typeDetail(vtexId),
    queryFn: fetchProductTypeEmptyTemplate,
    keepPreviousData: true,
    enabled: enabled,
  })
}
