import { useEffect } from 'react'
import { BsQuestionLg } from 'react-icons/bs'

import { DownOutlined, SearchOutlined, UpOutlined } from '@ant-design/icons'
import { CollapseProps, Divider, Form, FormInstance, Skeleton, Switch, Tooltip } from 'antd'

import { Tiptap } from './components'
import * as S from './styles'

import { useToggle } from '@/common'
import type { CreateProductFormValues, TechnicalSpecification } from '@/features/BG'
import {
  SpecificationsHelpDrawer,
  useProductTypeEmptyTemplate,
  useProductTypeTemplate,
} from '@/features/BG'

interface SpecificationsProps {
  productType: number
  form: FormInstance
  formValues: CreateProductFormValues
  selectedTemplate?: string
  infoButton: React.DispatchWithoutAction
  initialValues?: TechnicalSpecification
}

export function Specifications({
  productType,
  form,
  formValues,
  selectedTemplate,
  infoButton,
  initialValues,
}: SpecificationsProps) {
  const [isSpecificationsHelpDrawerOpen, toggleSpecificationsHelpDrawer] = useToggle(false)

  const hasSelectedTemplate = !!selectedTemplate

  const { data: emptyTemplate, isInitialLoading: isEmptyTemplateLoading } =
    useProductTypeEmptyTemplate(productType, !hasSelectedTemplate)

  const { data: productTemplate, isInitialLoading: isProductTemplateLoading } =
    useProductTypeTemplate(selectedTemplate!, hasSelectedTemplate)

  const isLoading = isEmptyTemplateLoading || isProductTemplateLoading

  const specData = hasSelectedTemplate ? productTemplate?.technical_specification : emptyTemplate

  useEffect(() => {
    if (specData) {
      const dataKeys = Object.keys(specData)

      const values = dataKeys.map((key) => {
        return {
          [key]: formValues[key] || initialValues?.[key] || specData[key], // if formValues[key] is undefined, use data[key]
        }
      })

      const valuesObject = Object.assign({}, ...values)

      form.setFieldValue('specifications', valuesObject)
    }
  }, [form, specData, formValues, initialValues])

  const items: CollapseProps['items'] =
    specData &&
    Object.entries(specData).map(([collapseLabel, collapseChildren], index) => {
      const initialValue =
        formValues?.[collapseLabel] || initialValues?.[collapseLabel] || collapseChildren

      return {
        key: index.toString(),
        label: collapseLabel,
        className: index % 2 === 0 ? `gray-collapse` : 'white-collapse',
        children: (
          <Form.Item name={collapseLabel} initialValue={initialValue} key={index}>
            <Tiptap
              label={collapseLabel}
              onChange={(richText) => {
                const updated = {
                  ...specData,
                  [collapseLabel]: richText,
                }

                form.setFieldValue('specifications', updated)
                return updated
              }}
            />
          </Form.Item>
        ),
      }
    })

  return (
    <div>
      {isSpecificationsHelpDrawerOpen && (
        <SpecificationsHelpDrawer
          isOpen={isSpecificationsHelpDrawerOpen}
          onClose={toggleSpecificationsHelpDrawer}
          formValues={formValues}
        />
      )}

      {isLoading ? (
        <Skeleton active />
      ) : (
        <S.Container>
          <S.HeaderContainer>
            <S.ProductCategoryContent>
              <S.ProductCategory>
                <span>{formValues?.product_type?.label || '--'}</span>

                <Divider type="vertical" style={{ margin: 0 }} />

                <strong>{formValues?.category?.label || '--'}</strong>

                <Divider type="vertical" style={{ margin: 0 }} />

                <span>{formValues.ref_id || '--'}</span>
              </S.ProductCategory>

              <S.HelpButton type="link" onClick={infoButton}>
                <Tooltip title="Instruções de preenchimento" placement="left">
                  <S.TooltipHelpIcon>
                    <BsQuestionLg />
                  </S.TooltipHelpIcon>
                </Tooltip>
              </S.HelpButton>
            </S.ProductCategoryContent>

            <S.BGName>{formValues.model}</S.BGName>

            <S.Content>
              <S.Typography>Resumo do produto</S.Typography>

              <S.UpgradeContainer>
                <S.UpgradeText>
                  Este produto pode ter <strong>upgrade</strong>
                </S.UpgradeText>

                <Form.Item name={'has_upgrade'} style={{ margin: 0 }}>
                  <Switch checkedChildren="Sim" unCheckedChildren="Não" />
                </Form.Item>
              </S.UpgradeContainer>
            </S.Content>
          </S.HeaderContainer>

          <Form.Item name={'overview'}>
            <Tiptap label="overview" onChange={(text) => text} />
          </Form.Item>

          <S.Typography>Características externas</S.Typography>

          <Form.Item name={'external_characteristics'}>
            <Tiptap label="external_characteristics" onChange={(text) => text} />
          </Form.Item>

          <S.SpecificationsContent>
            <S.Typography>Especificações</S.Typography>

            <S.SpecificationsButtons
              type="link"
              icon={<SearchOutlined />}
              onClick={toggleSpecificationsHelpDrawer}
            >
              Consultar Especificações
            </S.SpecificationsButtons>
          </S.SpecificationsContent>

          <S.Collapse
            accordion
            items={items}
            expandIconPosition="start"
            bordered={false}
            expandIcon={({ isActive }) =>
              isActive ? (
                <UpOutlined style={{ color: '#80c343' }} />
              ) : (
                <DownOutlined style={{ color: '#80c343' }} />
              )
            }
          />
        </S.Container>
      )}
    </div>
  )
}
