import { CheckCircleOutlined } from '@ant-design/icons'
import { Typography } from 'antd'
import { rem } from 'polished'
import { styled } from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  .ant-typography {
    line-height: normal;
  }

  .ant-form-item {
    label {
      color: #666;
    }
  }
`

export const Text = styled(Typography.Text)`
  color: #6c6c6c;
  font-size: ${rem(16)};

  strong {
    font-weight: 700;
  }
`

export const ToastContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

export const CircleOutlined = styled(CheckCircleOutlined)`
  display: flex;
  align-items: center;
  justify-content: center;

  color: #fff;
  width: 14px;
  height: 14px;
  border-radius: 99%;
  background-color: #80c343;
`
