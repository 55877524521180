import { useEffect, useState } from 'react'

import { LinkOutlined } from '@ant-design/icons'
import { Select, Form, Input, FormInstance, Tooltip } from 'antd'

import * as S from './styles'

import { ProductSpecification, formatCategories, useCategories } from '@/features/BG'

export type CreateDataBgFormData = {
  category?: string | undefined
  model?: string | undefined
  hero_image?: string | undefined
  dealer_page?: string | undefined
  pack?: string | undefined
  family?: string | undefined
}

type CreateBGFormProps = {
  form: FormInstance
  onSubmit: (data: CreateDataBgFormData) => void
  isRegistering: boolean
  productSpecification: ProductSpecification | undefined
}

const BgDataForm = ({ form, onSubmit, isRegistering, productSpecification }: CreateBGFormProps) => {
  const [categoryLabel] = useState(() => {
    if (productSpecification?.specification_monitor) {
      return 'MONITOR'
    }

    if (productSpecification?.specification_notebook) {
      return 'NOTEBOOK'
    }
  })

  const { data: categories, isLoading: isCategoriesLoading } = useCategories()

  const selectedCategory = Form.useWatch('category', form)
  const productFamily = Form.useWatch('family', form)?.length || 0

  const formattedCategories = formatCategories(categories?.categories || [])

  const categorySelectOptions = Object.entries(formattedCategories).map(([key, value]) => ({
    label: key,
    options: value.map((category) => ({
      key: category.id,
      label: category.name,
      value: category.id,
    })),
  }))

  const filteredCategoryOptions = categorySelectOptions.filter(
    (category) => category.label.toUpperCase() === categoryLabel,
  )

  useEffect(() => {
    if (selectedCategory) {
      const isValidCategory = filteredCategoryOptions.some((categoryGroup) =>
        categoryGroup.options.some((option) => option.value === selectedCategory),
      )

      if (!isValidCategory) {
        form.setFieldValue('category', '')
      }
    }
  }, [filteredCategoryOptions, selectedCategory, form])

  return (
    <Form
      onFinish={onSubmit}
      layout="vertical"
      form={form}
      disabled={isRegistering}
      requiredMark="optional"
    >
      <S.InputsRowContainer>
        <Form.Item
          name="category"
          label={<S.Label>Categoria</S.Label>}
          rules={[{ required: true, message: 'Selecione a categoria do produto.' }]}
        >
          <Select
            options={filteredCategoryOptions}
            loading={isCategoriesLoading}
            disabled={Object.keys(categorySelectOptions).length === 0 || isRegistering}
          />
        </Form.Item>

        <Form.Item
          name="model"
          label={<S.Label>Modelo</S.Label>}
          rules={[{ required: true, message: 'Informe o modelo do produto.' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="family"
          label={
            <S.Label>
              Familia <span>({productFamily}/30)</span>
            </S.Label>
          }
          rules={[
            { required: true, message: 'Informe a família do produto.' },
            { max: 29, message: 'A família deve ter no máximo 30 caracteres.' },
          ]}
        >
          <Input maxLength={30} />
        </Form.Item>
      </S.InputsRowContainer>

      <S.InputsColumnContainer>
        <Form.Item
          name="hero_image"
          label={
            <S.TooltipContainer>
              <S.InputName>Hero Image</S.InputName>

              <Tooltip title="Insira a URL do caminho das imagens que estão armazenadas no site Parceiros Acer">
                <S.TooltipIcon />
              </Tooltip>
            </S.TooltipContainer>
          }
          rules={[
            {
              required: true,
              message: 'Informe o link da Hero Image.',
            },
            { type: 'url', message: 'Deve ser um link.' },
          ]}
        >
          <Input className="linkInputs" suffix={<LinkOutlined />} />
        </Form.Item>

        <Form.Item
          name="dealer_page"
          label={
            <S.TooltipContainer>
              <S.InputName>Dealer Page</S.InputName>

              <Tooltip title="Insira a URL do caminho da pasta onde se encontram os arquivos da dealer page, que estão armazenados no site Parceiros Acer">
                <S.TooltipIcon />
              </Tooltip>
            </S.TooltipContainer>
          }
          rules={[
            { required: true, message: 'Informe o link da Dealer Page.' },
            { type: 'url', message: 'Deve ser um link.' },
          ]}
        >
          <Input className="linkInputs" suffix={<LinkOutlined />} />
        </Form.Item>

        <Form.Item
          name="pack"
          label={
            <S.TooltipContainer>
              <S.InputName>Pack</S.InputName>

              <Tooltip title="Insira a URL do caminho do arquivo .ZIP das imagens do BG, que estão armazenadas no site Parceiros Acer">
                <S.TooltipIcon />
              </Tooltip>
            </S.TooltipContainer>
          }
          rules={[
            { required: true, message: 'Informe o link do Pack.' },
            { type: 'url', message: 'Deve ser um link.' },
          ]}
        >
          <Input className="linkInputs" suffix={<LinkOutlined />} />
        </Form.Item>
      </S.InputsColumnContainer>
    </Form>
  )
}
export { BgDataForm }
