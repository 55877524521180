import { useMutation, useQueryClient } from '@tanstack/react-query'

import { productKeys } from '../../queries'
import { Product } from '../../types'

import { api } from '@/services'

interface ChangeStatusMutation {
  product_id: string
  status: boolean
}

export const useChangeStatusMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ product_id, status }: ChangeStatusMutation) => {
      return api.patch(`products/${product_id}/published_or_unpublished`, {
        is_active: status,
      })
    },
    onMutate: async (editedStatus) => {
      await queryClient.cancelQueries(productKeys.all)

      const previousStatus = queryClient.getQueryData<Product[]>(productKeys.lists())

      if (previousStatus) {
        queryClient.setQueryData<Product[]>(productKeys.lists(), (productList) => {
          return productList?.map((product) => {
            if (product.id === editedStatus.product_id) {
              return {
                ...product,
                is_active: !product.is_active,
              }
            }

            return product
          })
        })
      }

      return previousStatus
    },
    onError: (error, _, previousStatus) => {
      console.log(error)

      if (previousStatus) {
        queryClient.setQueryData(productKeys.lists(), previousStatus)
      }
    },
    onSettled: () => queryClient.invalidateQueries(productKeys.all),
  })
}
