import { useMutation, useQueryClient } from '@tanstack/react-query'

import { api } from '@/services'

import { productKeys } from '..'

export const useDeleteProductMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (productId: string) => {
      return api.delete(`/products/${productId}`)
    },
    onMutate: async (productId) => await queryClient.cancelQueries(productKeys.detail(productId)),
    onSettled: () => queryClient.invalidateQueries(productKeys.all),
  })
}
