import * as S from './styles'

const ContentHelpFourthStep = () => {
  return (
    <>
      <S.DescriptionContent>
        <S.DescriptionTitle>Tela de Visualização:</S.DescriptionTitle>

        <S.DescriptionText>
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Non commodi tempora voluptas, dolores odit sequi. Esse libero, quos debitis eaque iusto provident expedita at velit, quisquam vel, impedit repudiandae deleniti?
        </S.DescriptionText>
      </S.DescriptionContent>
    </>
  )
}

export { ContentHelpFourthStep }
