import { api } from '@/services'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { productKeys } from '../queries'

type EditGeneralInformationMutation = {
  productId: string
  model: string
  family: string
}

export const useEditGeneralInformationMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ productId, ...data }: EditGeneralInformationMutation) => {
      return api.patch(`/products/${productId}/model_and_family`, data)
    },
    onSettled: (_, __, { productId }) =>
      queryClient.invalidateQueries(productKeys.detail(productId)),
  })
}
