import { CheckCircleOutlined } from '@ant-design/icons'
import { Button as AntdButton } from 'antd'
import { rem } from 'polished'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export const Text = styled.div`
  display: flex;

  font-size: ${rem(12)};
  padding-bottom: 12px;

  color: #6c6c6c;
`

export const ButtonContainer = styled.div`
  .ant-btn {
    height: 0;
  }
`

export const CancelButton = styled(AntdButton)`
  color: ${({ theme }) => theme.colors.darkGreen};
`

export const Button = styled(AntdButton)`
  padding: 0;
  font-size: 14px;

  color: ${({ theme }) => theme.colors.darkGreen};
`

export const ToastContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

export const CircleOutlined = styled(CheckCircleOutlined)`
  display: flex;
  align-items: center;
  justify-content: center;

  color: #fff;
  width: 14px;
  height: 14px;
  border-radius: 99%;
  background-color: #80c343;
`
