import { useState } from 'react'

import { Form, FormInstance, Input, Select } from 'antd'

import * as S from './styles'

import { filtersRemap, useDebounce } from '@/common'
import { HelpTooltip } from '@/components'
import { useAnymarketCategories, useGlobalCategories, useProductTypes } from '@/features/BG/queries'

export type EditCategoriesFormData = {
  name: string
  keywords: string
  title: string
  description: string
  global_category_id: { value: number; label: string }
  father_category_id: number
  anymarket_id: number
}

interface EditCategoriesFormProps {
  onEdit: (data: EditCategoriesFormData) => void
  form: FormInstance
  isLoading: boolean
}

const EditCategoriesForm = ({ onEdit, isLoading, form }: EditCategoriesFormProps) => {
  const [globalCategoryName, setGlobalCategoryName] = useState('')
  const debouncedGlobalCategory = useDebounce(globalCategoryName, 500)

  const { data: productTypes } = useProductTypes({
    page: 1,
    per_page: 100,
  })

  const {
    data: globalcategories,
    isLoading: isGlobalCategoriesLoading,
    isFetching: isGlobalCategoriesFetching,
  } = useGlobalCategories(debouncedGlobalCategory)

  const { data: anymarketCategories, isLoading: isAnymarketCategoriesLoading } =
    useAnymarketCategories()

  const productTypeOptions = productTypes?.product_types?.map((value) => ({
    value: value.vtex_id,
    label: (filtersRemap.get(value.name) as string) || value.name,
  }))

  const globalCategoriesOptions = globalcategories?.google_global_categories.map((value) => ({
    value: value.google_id,
    label: value.name,
  }))

  const anymarketCategoriesOptions = anymarketCategories?.map((category) => ({
    value: category.id,
    label: category.name,
  }))

  return (
    <S.Form form={form} onFinish={onEdit} disabled={isLoading} layout="vertical" requiredMark>
      <Form.Item
        name={'name'}
        label={'Nome'}
        rules={[{ required: true, message: 'Campo obrigatório' }]}
      >
        <Input />
      </Form.Item>

      <S.Row>
        <Form.Item
          name={'title'}
          label={'Título da Página'}
          rules={[{ required: true, message: 'Campo obrigatório' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name={'keywords'}
          label={
            <S.LabelContainer>
              <span>Palavras Similares</span>

              <HelpTooltip title="As palavras similares devem ser separadas usando vírgula." />
            </S.LabelContainer>
          }
          rules={[{ required: true, message: 'Campo obrigatório' }]}
        >
          <Input />
        </Form.Item>
      </S.Row>

      <S.Row>
        <Form.Item
          name={'global_category_id'}
          label={'Categoria Global'}
          rules={[{ required: true, message: 'Campo obrigatório' }]}
        >
          <Select
            showSearch
            labelInValue
            size="large"
            placeholder="Selecione a categoria global"
            options={globalCategoriesOptions}
            loading={isGlobalCategoriesLoading || isGlobalCategoriesFetching}
            onSearch={setGlobalCategoryName}
            filterOption={false}
          />
        </Form.Item>

        <Form.Item
          name={'father_category_id'}
          label={'Tipo de Produto'}
          rules={[{ required: true, message: 'Campo obrigatório' }]}
        >
          <Select
            size="large"
            placeholder="Selecione o tipo de produto"
            options={productTypeOptions}
            disabled
          />
        </Form.Item>

        <Form.Item
          name={'anymarket_id'}
          label={'Categoria Anymarket'}
          rules={[{ required: true, message: 'Campo obrigatório' }]}
        >
          <Select
            size="large"
            placeholder="Selecione a categoria Anymarket"
            options={anymarketCategoriesOptions}
            loading={isAnymarketCategoriesLoading}
          />
        </Form.Item>
      </S.Row>

      <Form.Item
        name={'description'}
        label={'Descrição'}
        rules={[{ required: true, message: 'Campo obrigatório' }]}
      >
        <Input.TextArea style={{ height: 80 }} />
      </Form.Item>
    </S.Form>
  )
}

export { EditCategoriesForm }
