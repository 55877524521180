import { BGProductFilters, CategoryFilters, ProductTypeFilters } from '@/app'

export const categoryKeys = {
  all: [{ scope: 'categories' }] as const,
  lists: () => [{ ...categoryKeys.all[0], entity: 'list' }] as const,
  list: (filters: CategoryFilters) => [{ ...categoryKeys.lists()[0], filters }] as const,
  globalCategories: (name: string) =>
    [{ ...categoryKeys.all[0], entity: 'globalCategory', name }] as const,
  categories: () => [{ ...categoryKeys.all[0], entity: 'categories' }] as const,
  category: (categoryId: string) => [{ ...categoryKeys.categories()[0], categoryId }],
  anymarketCategories: () => [{ ...categoryKeys.all[0], entity: 'anymarketCategories' }] as const,
  anymarketCategory: (anymarketCategoryId: string) =>
    [{ ...categoryKeys.anymarketCategories()[0], anymarketCategoryId }] as const,
}

export const productKeys = {
  all: [{ scope: 'dashboard' }] as const,
  lists: () => [{ ...productKeys.all[0], entity: 'list' }] as const,
  list: (filters: BGProductFilters) => [{ ...productKeys.lists()[0], filters }] as const,
  quantityByStatus: () => [{ ...productKeys.all[0], entity: 'quantity_by_status' }] as const,
  details: () => [{ ...productKeys.all[0], entity: 'details' }] as const,
  detail: (bgId: string) => [{ ...productKeys.details()[0], bgId }] as const,
  timelines: () => [{ ...productKeys.all[0], entity: 'timeline' }] as const,
  timeline: (productId: string) => [{ ...productKeys.timelines()[0], productId }] as const,
  specifications: () => [{ ...productKeys.all[0], entity: 'specifications' }] as const,
  specification: (categoryId: string) =>
    [{ ...productKeys.specifications()[0], categoryId }] as const,
  filtersAndIcons: (categoryVtexId: number) =>
    [{ ...productKeys.specifications()[0], categoryVtexId }] as const,
  types: () => [{ ...productKeys.all[0], entity: 'types' }] as const,
  type: (filters: ProductTypeFilters) => [{ ...productKeys.types()[0], filters }] as const,
  typeDetails: () => [{ ...productKeys.all[0], entity: 'types-details' }] as const,
  typeDetail: (vtexId: number) => [{ ...productKeys.typeDetails()[0], vtexId }] as const,
  groupFields: () => [{ ...productKeys.all[0], entity: 'groupFields' }] as const,
  groupField: (vtexId: number) =>
    [{ ...productKeys.groupFields()[0], entity: 'groupField', vtexId }] as const,
  productTypes: () => [{ ...productKeys.all[0], entity: 'productTypes' }] as const,
  productType: (productTypeId: number) =>
    [{ ...productKeys.productTypes()[0], entity: 'productType', productTypeId }] as const,
}

export const templateKeys = {
  all: [{ scope: 'templates' }] as const,
  lists: () => [{ ...templateKeys.all[0], entity: 'list' }] as const,
  list: (productTypeVtexId: number) => [{ ...templateKeys.lists()[0], productTypeVtexId }] as const,
  details: () => [{ ...templateKeys.all[0], entity: 'details' }] as const,
  detail: (model: string) => [{ ...templateKeys.details()[0], model }] as const,
}

export const scrapingKeys = {
  all: [{ scope: 'scrapings' }] as const,
  lists: () => [{ ...scrapingKeys.all[0], entity: 'list' }] as const,
  list: (scrapingType: string, search: string) =>
    [{ ...scrapingKeys.lists()[0], scrapingType, search }] as const,
}

export const bomKeys = {
  all: [{ scope: 'boms' }] as const,
  lists: () => [{ ...bomKeys.all[0], entity: 'list' }] as const,
  list: (product_model: string) => [{ ...bomKeys.lists()[0], product_model }] as const,
}
