import { useMutation, useQueryClient } from '@tanstack/react-query'

import { categoryKeys } from '../../queries'
import { CategoryType } from '../../types'

import { api } from '@/services'

interface EditCategoriesMutation {
  id: string
  name: string
  keywords: string
  title: string
  description: string
  google_global_category_id: number
  anymarket_id: number
}

export const editCategoriesErrorMessages = new Map([
  ['Category name already exists.', 'O nome desta categoria já existe.'],
  [
    'Value error, Keywords must be separated by comma',
    'Palavras Similares precisam ser separadas por vírgula.',
  ],
])

export const useEditCategoriesMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (data: EditCategoriesMutation) => {
      return api.put(`/categories/${data.id}`, data)
    },
    onMutate: async (editedCategories) => {
      await queryClient.cancelQueries(categoryKeys.all)

      const previousCategories = queryClient.getQueryData<CategoryType[]>(categoryKeys.lists())

      if (previousCategories) {
        queryClient.setQueryData<CategoryType[]>(categoryKeys.lists(), (categoryList) => {
          return categoryList?.map((category) => {
            if (category.id === editedCategories.id) {
              return {
                ...category,
                ...editedCategories,
              }
            }

            return category
          })
        })
      }

      return previousCategories
    },
    onError: (error, _, previousCategories) => {
      console.log(error)

      if (previousCategories) {
        queryClient.setQueryData(categoryKeys.lists(), previousCategories)
      }
    },
    onSettled: () => queryClient.invalidateQueries(categoryKeys.all),
  })
}
