import { useEffect } from 'react'

import { Button, Form } from 'antd'
import { toast } from 'sonner'

import { EditProductTypeModal, EditProductTypesFormData } from '../../Form'
import * as S from './styles'

import { ApiError, BaseModalProps, filtersRemap, toastError, useToggle } from '@/common'
import { BaseModal } from '@/components'
import { editProductTypeErrorMessages, useEditProductTypeMutation } from '@/features/BG/mutations'
import { ProductType } from '@/features/BG/types'

interface ProductTypeModalProps extends BaseModalProps {
  data: ProductType
}

const ProductTypeModal = ({ isOpen, onClose, data }: ProductTypeModalProps) => {
  const [form] = Form.useForm()

  const [isEditing, toggleEdit] = useToggle(false)

  const editProductTypeMutation = useEditProductTypeMutation()

  useEffect(() => {
    const initialData = {
      name: filtersRemap.get(data.name) ? filtersRemap.get(data.name) : data.name,
      global_category_id: data.global_category.google_id,
    }

    if (initialData) {
      form.setFieldsValue(initialData)
    }
  }, [form, data])

  async function handleEditProductType(values: EditProductTypesFormData) {
    const payload = {
      id: data.id,
      name: values.name,
      global_category_id: values.global_category_id,
    }

    const promise = editProductTypeMutation.mutateAsync(payload)

    toast.promise(promise, {
      loading: 'Editando tipo de produto...',
      success: () => {
        onClose()

        return (
          <S.ToastContainer>
            <S.CircleOutlined />

            <div>
              Tipo de produto <span style={{ fontWeight: 'bold' }}>editado</span> com{' '}
              <span style={{ fontWeight: 'bold' }}>sucesso</span>!
            </div>
          </S.ToastContainer>
        )
      },
      className: 'toast-sucess',
      error: (error: ApiError) =>
        toastError({
          error,
          errorMessages: editProductTypeErrorMessages,
          defaultMessage: 'Erro ao editar tipo do produto.',
        }),
    })
  }

  const ProductTypeView = () => (
    <>
      <S.FieldRow>
        <S.ProductTypeData>
          <strong>Nome</strong>

          <span>{(filtersRemap.get(data.name) as string) || data.name}</span>
        </S.ProductTypeData>

        <S.ProductTypeData>
          <strong>Categoria Global</strong>

          <span>{data.global_category.name}</span>
        </S.ProductTypeData>
      </S.FieldRow>
    </>
  )

  return (
    <BaseModal
      open={isOpen}
      onCancel={onClose}
      title="Dados cadastrais"
      width={740}
      footer={
        <>
          {!isEditing ? (
            <Button
              onClick={onClose}
              type="link"
              htmlType="button"
              disabled={editProductTypeMutation.isLoading}
            >
              Cancelar
            </Button>
          ) : (
            <Button type="link" onClick={toggleEdit}>
              Voltar
            </Button>
          )}

          {!isEditing ? (
            <>
              <Button type="primary" onClick={toggleEdit}>
                Editar
              </Button>
            </>
          ) : (
            <Button
              type="primary"
              htmlType="submit"
              onClick={() => form.submit()}
              disabled={editProductTypeMutation.isLoading}
            >
              Salvar
            </Button>
          )}
        </>
      }
    >
      <S.Container>
        {!isEditing ? (
          <ProductTypeView />
        ) : (
          <EditProductTypeModal
            form={form}
            onEdit={handleEditProductType}
            isLoading={editProductTypeMutation.isLoading}
          />
        )}
      </S.Container>
    </BaseModal>
  )
}

export { ProductTypeModal }
