import { Link } from 'react-router-dom'

import { DeleteOutlined, HistoryOutlined, ExclamationCircleFilled } from '@ant-design/icons'
import { Image, Button, Divider, Modal, Badge } from 'antd'

import { BgStatusTags, Product, formatProductType, hasHeroImage } from '../..'
import * as S from './styles'

import anymarketLogo from '@/assets/logo-anymarket.svg'
import vtexLogo from '@/assets/logo-vtex-single.svg'
import { formatCurrency, formatDate, imgFallback, truncateString, useAuth } from '@/common'
import { TableAction } from '@/components'
import { SelectProductActions } from '@/pages'

type BgCardProps = {
  data: Product
  isLoading?: boolean
  handleSelectProduct: ({ product, type }: SelectProductActions) => void
}

export const BgCard = ({ data, handleSelectProduct }: BgCardProps) => {
  const { getRolePermissions } = useAuth()

  const rolePermissions = getRolePermissions()

  const navigateRoute =
    data?.status === 'EM RASCUNHO DO PRODUTO' ? `/create-product/${data.id}` : `/product/${data.id}`

  const canDeleteProduct =
    data.status === 'EM RASCUNHO DO PRODUTO' ||
    data.status === 'PENDENTE DE ENVIO PARA AGÊNCIA' ||
    data.status === 'PENDENTE DE LIBERAÇÃO DA AGÊNCIA'

  const dropdownOptions = [
    {
      key: '1',
      label: (
        <S.ButtonContent>
          <Button
            icon={<HistoryOutlined />}
            type="link"
            onClick={(e) => {
              e.preventDefault()
              // e.stopPropagation()

              handleSelectProduct({ product: data, type: 'VIEW_HISTORY' })
            }}
          >
            Ver histórico
          </Button>
        </S.ButtonContent>
      ),
    },
    (rolePermissions?.product?.isAdmin || rolePermissions?.product?.canDelete) && canDeleteProduct
      ? {
          key: '2',
          label: (
            <S.ButtonContent>
              <Button
                danger
                type="link"
                icon={<DeleteOutlined />}
                onClick={(e) => {
                  e.preventDefault()
                  // e.stopPropagation()

                  confirmDeletion(data)
                }}
                disabled={!canDeleteProduct}
              >
                Excluir
              </Button>
            </S.ButtonContent>
          ),
        }
      : null,
  ]

  function confirmDeletion(product: Product) {
    Modal.confirm({
      title: `Deseja realmente excluir este produto?`,
      content: (
        <span>
          O produto <strong>{truncateString(product.name || product.model, 90)}</strong> será
          excluido permanentemente.
        </span>
      ),
      centered: true,
      icon: <ExclamationCircleFilled style={{ color: '#FF0000' }} />,
      okText: 'Excluir',
      cancelText: 'Cancelar',
      footer: (_, { OkBtn, CancelBtn }) => (
        <S.ModalFooter>
          <CancelBtn />
          <OkBtn />
        </S.ModalFooter>
      ),
      okButtonProps: {
        type: 'primary',
        style: {
          color: '#FFF',
          background: '#80c343',
        },
      },
      cancelButtonProps: {
        danger: true,
      },
      onOk() {
        handleSelectProduct({ product, type: 'DELETE_PRODUCT' })
      },
    })
  }

  return (
    <Link to={navigateRoute}>
      {data?.vtex_legacy ? (
        <Badge.Ribbon
          color="#FF3366"
          text="Importado VTEX"
          style={{ zIndex: 1, top: 0 }}
          placement="start"
        />
      ) : null}

      <S.Container>
        <S.ImageContainer>
          <Image
            height={152}
            width={174}
            preview={false}
            fallback={imgFallback}
            alt={`Hero image de ${data.name}`}
            src={
              hasHeroImage(data?.status)
                ? data?.partner_files?.hero_image_partner_url
                : data?.sku?.sku_files?.[0]?.filename_url
            }
            style={{ objectFit: 'contain' }}
          />
        </S.ImageContainer>

        <TableAction
          isCard
          trigger={['hover']}
          icon={<S.DropdownIcon />}
          dropdownOptions={dropdownOptions}
        />

        <S.ProductCategory>
          <span>{formatProductType(data.product_type_name) || '--'}</span>

          <Divider type="vertical" style={{ margin: 0 }} />

          <strong>{truncateString(data.category_name, 20) || '--'}</strong>
        </S.ProductCategory>

        <S.ProductFamily>{truncateString(data?.family, 28) || '--'}</S.ProductFamily>

        <S.BGName>{truncateString(data.name || data.model, 90)}</S.BGName>

        <Divider style={{ margin: 0 }} />

        <S.InfoContainer $hasAnymarketPrice={!!data?.sku?.price_anymarket || false}>
          <S.DateContainer>
            <span>Atualizado em</span>
            <S.Date>{formatDate({ date: data.updated_at, dateFormat: 'DD/MM/YYYY' })}</S.Date>
          </S.DateContainer>

          <S.PriceContainer>
            <S.ProductPrice>
              {data?.sku?.price ? (
                <Image src={vtexLogo} width={14} alt="Logo VTEX" preview={false} />
              ) : null}

              {formatCurrency({ value: data?.sku?.price, type: 'currency' }) || '--'}
            </S.ProductPrice>

            {data?.sku?.price_anymarket ? (
              <S.ProductPrice>
                <Image src={anymarketLogo} width={16} alt="Logo AnyMarket" preview={false} />

                {formatCurrency({ value: data?.sku?.price_anymarket, type: 'currency' }) || '--'}
              </S.ProductPrice>
            ) : null}
          </S.PriceContainer>
        </S.InfoContainer>

        <BgStatusTags status={data.status} />
      </S.Container>
    </Link>
  )
}
