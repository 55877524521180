import axios from 'axios'
import jwtDecode from 'jwt-decode'

import authStore from '@/app/stores/auth'
import rolesStore from '@/app/stores/roles'
import { ApiError, clearAllCaches } from '@/common'
import { RefreshLoginResponse } from '@/features/auth'

export const api = axios.create({
  baseURL: `${import.meta.env.VITE_API_URL}`,
})

api.defaults.headers.common.Authorization = `Bearer ${authStore.getState().idToken}`

let isRefreshing = false
let failedRequestsQueue: any[] = []

api.interceptors.response.use(
  (response) => response,
  (error: ApiError) => {
    if (error.response?.status === 400 && error.response.data.detail === 'Token is no valid') {
      const idToken = authStore.getState().idToken
      const refreshToken = authStore.getState().refreshToken

      const originalConfig = error.config

      if (!isRefreshing) {
        isRefreshing = true

        const decodedIdToken = jwtDecode<{ 'cognito:username': string }>(idToken as string)
        const userId = decodedIdToken['cognito:username']

        api
          .post('/login_unico/refresh_token', {
            refresh_token: refreshToken as string,
            user_id: userId,
          })
          .then(({ data }) => {
            const { AccessToken, IdToken, RefreshToken, UserPermissions } =
              data as RefreshLoginResponse

            authStore.setState({
              accessToken: AccessToken,
              idToken: IdToken,
              refreshToken: RefreshToken,
              isUserAuthenticated: true,
            })

            api.defaults.headers.common.Authorization = `Bearer ${IdToken}`

            rolesStore.getState().getUserRoles(IdToken)
            rolesStore.setState({ userRoles: UserPermissions })

            failedRequestsQueue.forEach((req) => req.onSuccess(IdToken))
            failedRequestsQueue = []
          })
          .catch((err) => {
            failedRequestsQueue.forEach((req) => req.onFailure(err))
            failedRequestsQueue = []
            clearAllCaches()
          })
          .finally(() => {
            isRefreshing = false
          })
      }

      return new Promise((resolve, reject) => {
        failedRequestsQueue.push({
          onSuccess: (token: string) => {
            originalConfig!.headers!.Authorization = `Bearer ${token}`

            resolve(api(originalConfig!))
          },
          onFailure: (error: ApiError) => reject(error),
        })
      })
    }

    return Promise.reject(error)
  },
)
