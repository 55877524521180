import { useQuery } from '@tanstack/react-query'

import { BGOverview } from '../../types'
import { productKeys } from '../query-keys'

import { api } from '@/services'

export const fetchQuantityByStatus = async () => {
  const { data } = await api.get<BGOverview[]>('/products/quantity_by_status')

  return data
}

export const useProductsQuantityByStatus = () =>
  useQuery({
    queryKey: productKeys.quantityByStatus(),
    queryFn: fetchQuantityByStatus,
    keepPreviousData: true,
    retry: false,
  })
