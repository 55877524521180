import { FileWordOutlined } from '@ant-design/icons'
import { rem } from 'polished'
import styled from 'styled-components'

export const InputsRowContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;

  width: 100%;
  height: fit-content;

  .ant-form-item {
    flex: 1;
  }

  .typeSelect {
    min-width: ${rem(116)};
    width: 100%;
    height: ${rem(32)};
  }

  .categorySelect {
    min-width: ${rem(182)};
    width: 100%;
    height: ${rem(32)};
  }

  .modelInput {
    min-width: ${rem(182)};
    width: 100%;
    height: ${rem(32)};
  }
`

export const TooltipContainer = styled.div`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.customTokens.input.label};
  gap: 4px;
`

export const TooltipIcon = styled.div`
  padding: 1px;
  font-size: 14px;
  height: 16px;
  width: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 99999999px;

  background-color: ${({ theme }) => theme.colors.brand};
  color: ${({ theme }) => theme.colors.white};
`

export const InputsColumnContainer = styled.div`
  display: flex;
  width: 100%;
  height: fit-content;
  gap: 14px;

  .ant-form-item {
    flex: 1;
  }

  .tooltipIcon {
    color: ${(props) => props.theme.colors.brand};
  }

  .uploadDraggerBox {
    height: ${rem(210)};
  }

  .ant-upload {
    height: ${rem(192)};
  }

  .linkInputs {
    height: ${rem(32)};
  }

  .linkInputsBox {
    height: ${rem(65)};
  }

  .ant-input-affix-wrapper {
    min-width: ${rem(214)};
    width: 100%;
  }
`

export const Label = styled.span`
  display: flex;
  align-items: center;
  gap: 4px;
  color: ${(props) => props.theme.customTokens.input.label};

  > span {
    font-size: 10px;
  }
`

export const UploadStatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
`

export const FileIcon = styled(FileWordOutlined)`
  color: ${({ theme }) => theme.colors.brand};
  font-size: 48px;
`

export const FileName = styled.span``

export const InputName = styled.span``
