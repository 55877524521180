import { useNavigate } from 'react-router-dom'

import { DeleteOutlined, ExclamationCircleFilled, HistoryOutlined } from '@ant-design/icons'
import { Button, Modal, Image } from 'antd'
import { ColumnsType } from 'antd/es/table'

import * as S from './styles'

import anymarketLogo from '@/assets/logo-anymarket.svg'
import vtexLogo from '@/assets/logo-vtex-single.svg'
import { formatCurrency, formatDate, imgFallback, useAuth } from '@/common'
import { BaseTable, TableAction } from '@/components'
import {
  Product,
  ProductStatus,
  formatProductType,
  BgStatusTags,
  hasHeroImage,
} from '@/features/BG'
import { SelectProductActions } from '@/pages'

type BgListTableProps = {
  data: Product[]
  isLoading?: boolean
  handleSelectProduct: ({ type, product }: SelectProductActions) => void
}

export const BgListTable = ({ data, isLoading = false, handleSelectProduct }: BgListTableProps) => {
  const { getRolePermissions } = useAuth()

  const rolePermissions = getRolePermissions()

  const navigate = useNavigate()

  const columns: ColumnsType<Product> = [
    {
      title: '',
      dataIndex: ['partner_files', 'hero_image_partner_url'],
      key: 'hero_image',
      render: (_, product) => {
        return (
          <Image
            fallback={imgFallback}
            alt={`Hero image de ${product.name}`}
            src={
              hasHeroImage(product.status)
                ? product.partner_files.hero_image_partner_url
                : product?.sku?.sku_files?.[0]?.filename_url
            }
            style={{ width: '62px', height: '56px' }}
          />
        )
      },
    },
    {
      title: 'Tipo',
      dataIndex: 'product_type_name',
      key: 'type',
      align: 'center',
      width: 100,
      render: (productType: string) => {
        return formatProductType(productType) || '--'
      },
    },
    {
      title: 'Família',
      dataIndex: 'family',
      key: 'family',
      align: 'center',
      width: 100,
      render: (family: string, product) => {
        const productFamily = (family || product.family?.[0] || '--').toLowerCase()

        return (
          <S.BgFamily>
            {productFamily.length > 30 ? `${productFamily.slice(0, 30)}...` : productFamily}
          </S.BgFamily>
        )
      },
    },
    {
      title: 'Titulo',
      dataIndex: 'name',
      key: 'name',
      render: (name: string, product) => {
        const navigateRoute =
          product?.status === 'EM RASCUNHO DO PRODUTO'
            ? `/create-product/${product.id}`
            : `/product/${product.id}`

        function handleClick() {
          if (
            (!rolePermissions?.product || !rolePermissions?.product?.canUpdate) &&
            product.status === 'EM RASCUNHO DO PRODUTO'
          ) {
            return
          }

          navigate(navigateRoute)
        }

        if (!name) {
          return <S.BgName onClick={handleClick}>{`${product.model} - ${product.ref_id}`}</S.BgName>
        }

        return <S.BgName onClick={handleClick}>{handleBgNameLength(name || '-')}</S.BgName>
      },
    },
    {
      title: 'Categoria',
      dataIndex: 'category_name',
      key: 'name',
      render: (category) => <S.BgCategoryDate>{category || '--'}</S.BgCategoryDate>,
    },
    {
      title: 'Data',
      dataIndex: 'updated_at',
      key: 'updated_at',
      align: 'center',
      render: (date: string) => {
        return (
          <S.BgCategoryDate>
            {formatDate({ date: date, dateFormat: 'DD/MM/YYYY' }) || '--'}
          </S.BgCategoryDate>
        )
      },
    },
    {
      title: 'Valor (R$)',
      dataIndex: 'sku',
      key: 'value',
      width: 150,
      align: 'center',
      render: (_, product) => {
        return (
          <S.PriceContainer>
            <S.Price
              $isUnpublished={
                product.status === 'DESPUBLICADO' || product.status === 'DESCONTINUADO'
              }
            >
              {product?.sku?.price && (
                <Image src={vtexLogo} width={14} alt="Logo VTEX" preview={false} />
              )}

              {formatCurrency({ value: product?.sku?.price, type: 'currency' }) || '--'}
            </S.Price>

            {product?.sku?.price_anymarket ? (
              <S.Price
                $isUnpublished={
                  product.status === 'DESPUBLICADO' || product.status === 'DESCONTINUADO'
                }
              >
                <Image src={anymarketLogo} width={14} alt="Logo VTEX" preview={false} />

                {formatCurrency({ value: product?.sku?.price_anymarket, type: 'currency' }) || '--'}
                {/* {formatCurrency({ value: product?.sku?.price }) || '--'} */}
              </S.Price>
            ) : null}
          </S.PriceContainer>
        )
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 100,
      align: 'center',
      render: (status: ProductStatus) => {
        return (
          <S.StatusContainer>
            <BgStatusTags status={status} />
          </S.StatusContainer>
        )
      },
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      align: 'center',
      width: 50,
      render: (_, product) => {
        const canDeleteProduct =
          product.status === 'EM RASCUNHO DO PRODUTO' ||
          product.status === 'PENDENTE DE ENVIO PARA AGÊNCIA' ||
          product.status === 'PENDENTE DE LIBERAÇÃO DA AGÊNCIA'

        const dropdownOptions = [
          {
            key: '1',
            label: (
              <S.ButtonContent>
                <Button
                  type="link"
                  icon={<HistoryOutlined />}
                  onClick={() => handleSelectProduct({ product, type: 'VIEW_HISTORY' })}
                >
                  Ver Histórico
                </Button>
              </S.ButtonContent>
            ),
          },
          (rolePermissions?.product?.isAdmin || rolePermissions?.product?.canDelete) &&
          canDeleteProduct
            ? {
                key: '2',
                label: (
                  <S.ButtonContent>
                    <Button
                      danger
                      type="link"
                      icon={<DeleteOutlined />}
                      onClick={() => confirmDeletion(product)}
                      disabled={!canDeleteProduct}
                    >
                      Excluir
                    </Button>
                  </S.ButtonContent>
                ),
              }
            : null,
        ]

        return <TableAction dropdownOptions={dropdownOptions} />
      },
    },
  ]

  const handleBgNameLength = (name: string): string => {
    if (name === null) return '--'
    if (name.length > 55) {
      return `${name.slice(0, 48)}...`
    }

    return name
  }

  function confirmDeletion(product: Product) {
    Modal.confirm({
      title: `Deseja realmente excluir este produto?`,
      content: (
        <span>
          O produto <strong>{handleBgNameLength(product.name || product.model)}</strong> será
          excluido permanentemente.
        </span>
      ),
      centered: true,
      icon: <ExclamationCircleFilled style={{ color: '#FF0000' }} />,
      okText: 'Excluir',
      cancelText: 'Cancelar',
      footer: (_, { OkBtn, CancelBtn }) => (
        <S.ModalFooter>
          <CancelBtn />
          <OkBtn />
        </S.ModalFooter>
      ),
      okButtonProps: {
        type: 'primary',
        style: {
          color: '#FFF',
          background: '#80c343',
        },
      },
      cancelButtonProps: {
        danger: true,
      },
      onOk() {
        handleSelectProduct({ product, type: 'DELETE_PRODUCT' })
      },
    })
  }

  return (
    <BaseTable
      dataSource={data}
      columns={columns}
      rowKey={(record, i) => `${record.id}-${i}`}
      loading={isLoading}
      pagination={false}
    />
  )
}
