import TextArea from 'antd/es/input/TextArea'
import { rem } from 'polished'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  .ant-form-item {
    margin-bottom: 5px;
  }

  .ant-select {
    height: ${rem(40)};
  }

  .ant-input-password {
    height: ${rem(40)};

    .ant-input {
      height: ${rem(30)};
    }
  }
`

export const TextAreaInput = styled(TextArea)``

export const NameInput = styled.div`
  .ant-input {
    height: ${rem(40)};
  }
`
