import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`

export const SwitchTitleArea = styled.div`
  display: flex;
  gap: 8px;
`
