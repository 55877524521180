import jwtDecode from 'jwt-decode'

import { authStore as useAuthStore } from '@/app'
import { UserGroup } from '@/features/auth'

export const useUserGroup = () => {
  const accessToken = useAuthStore((s) => s.accessToken)

  if (!accessToken) {
    return { userGroup: null, isAdmin: false }
  }

  const decodedToken = jwtDecode<{ 'cognito:groups': string[] }>(accessToken)

  const userGroup = decodedToken['cognito:groups']?.[0] as UserGroup

  const isAdmin = userGroup === 'ADMIN' || userGroup === 'MASTER'

  return { userGroup, isAdmin }
}
