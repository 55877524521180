import { QueryFunctionContext, useQuery } from '@tanstack/react-query'

import { scrapingKeys } from '../query-keys'

import { ScrapingResult, Fabricantes } from '@/features/users'
import { api } from '@/services'

interface ScrapingQuery {
  scrapingType: Fabricantes
  search: string
  enabled?: boolean
}

const fetchScraping = async ({
  queryKey,
}: QueryFunctionContext<ReturnType<(typeof scrapingKeys)['list']>>) => {
  const [{ scrapingType, search }] = queryKey

  const { data } = await api.get<ScrapingResult>(`/scraping?${scrapingType}=${search}`)

  return data
}

export const useScraping = ({ scrapingType, search, enabled }: ScrapingQuery) => {
  return useQuery({
    queryKey: scrapingKeys.list(scrapingType, search),
    queryFn: fetchScraping,
    keepPreviousData: true,
    enabled: enabled,
    retry: 1,
  })
}
