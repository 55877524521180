import { useState } from 'react'

import { Form, FormInstance, Input, Select } from 'antd'

import * as S from './styles'

import { useDebounce } from '@/common'
import { useGlobalCategories } from '@/features/BG/queries'

export type CreateProductTypeFormData = {
  name: string
  global_category_id: number
}

interface CreateProductTypeFormProps {
  onSubmit?: (data: CreateProductTypeFormData) => void
  isSubmitting?: boolean
  form: FormInstance
}

const CreateProductTypeForm = ({ onSubmit, isSubmitting, form }: CreateProductTypeFormProps) => {
  const [globalCategoryName, setGlobalCategoryName] = useState('')
  const debouncedGlobalCategory = useDebounce(globalCategoryName, 500)

  const {
    data: globalcategories,
    isFetching,
    isLoading,
  } = useGlobalCategories(debouncedGlobalCategory)

  const globalCategoriesOptions = globalcategories?.google_global_categories.map((value) => ({
    value: value.google_id,
    label: value.name,
  }))

  return (
    <S.Form
      form={form}
      onFinish={onSubmit}
      disabled={isSubmitting}
      layout="vertical"
      requiredMark={false}
    >
      <S.Row>
        <Form.Item
          name={'name'}
          label={'Nome'}
          rules={[{ required: true, message: 'Campo obrigatório' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name={'global_category_id'}
          label={'Categoria Global'}
          rules={[{ required: true, message: 'Campo obrigatório' }]}
        >
          <Select
            showSearch
            size="large"
            placeholder="Selecione a categoria"
            options={globalCategoriesOptions}
            loading={isLoading || isFetching}
            onSearch={setGlobalCategoryName}
            filterOption={false}
          />
        </Form.Item>
      </S.Row>
    </S.Form>
  )
}

export { CreateProductTypeForm }
