import { Button } from 'antd'
import styled from 'styled-components'

export const GroupsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`

export const GroupButton = styled(Button)`
  font-size: 14px;
  font-weight: 600;
  padding: 0;

  cursor: default;
  pointer-events: none;
`

export const ButtonContent = styled.div`
  .ant-btn {
    padding: 0;
  }
`

export const ModalFooter = styled.div`
  display: flex;
  justify-content: space-between;
`

export const GroupsTooltip = styled.div`
  height: 24px;
  width: 24px;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 14px;
  color: #fff;
  background-color: #ff8b27;
  border-radius: 999999px;

  cursor: default;
`

export const GroupsList = styled.div`
  display: flex;
  flex-direction: column;

  h3 {
    color: #525252;
    font-weight: 700;
    padding: 8px 8px 0;
  }

  .ant-divider {
    margin: 8px 0;
  }
`

export const Group = styled.span`
  color: #525252;
  padding: 0 16px;
`
