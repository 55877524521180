import { useMutation, useQueryClient } from '@tanstack/react-query'

import { productKeys } from '../queries'

import { api } from '@/services'

export const useIsAvailableToAgencyMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (productId: string) => {
      return api.patch(`/products/${productId}/available_to_agency`)
    },
    onMutate: async () => await queryClient.cancelQueries(productKeys.all),
    onSettled: (_, __, productId) => queryClient.invalidateQueries(productKeys.detail(productId)),
  })
}
