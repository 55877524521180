import { useMutation } from '@tanstack/react-query'

import { api } from '@/services'

interface RegisterDataBgMutation {
  category: string
  model: string
  hero_image: string
  dealer_page: string
  pack: string
  product_id: string | undefined
  family: string
}

export const registerDataBgErrorMessages = new Map([
  ['The given category id is not valid', 'A categoria não é válida!'],
  [
    'The category must belong to the product type',
    'A categoria deve pertencer ao tipo do produto!',
  ],
  [
    'Registration for this product is already complete',
    'O registro desse produto já está completo!',
  ],
])

export const useRegisterDataBgMutation = () => {
  return useMutation({
    mutationFn: async (data: RegisterDataBgMutation) => {
      return api.patch(`/v1.1/products/${data.product_id}`, data)
    },
  })
}
