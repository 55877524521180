import { CameraOutlined } from '@ant-design/icons'
import { Typography } from 'antd'
import { rem } from 'polished'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  .ant-typography {
    line-height: normal;
  }
`

export const Content = styled.div`
  display: flex;
  gap: 12px;
`

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;

  width: ${rem(180)};
  height: ${rem(180)};
  border-radius: 6px;

  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
  background-color: #fff;
`

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  flex: 1;
`

export const StatusContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  padding-bottom: 8px;
`

export const SummaryContainer = styled.div`
  width: 100%;
  height: 100%;

  max-height: 250px;
  overflow-y: auto;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);

  padding: ${rem(14)};
  border-radius: 6px;
  gap: 8px;

  line-height: initial;

  li {
    line-height: normal;
  }
`

export const Summary = styled.ul`
  background-color: ${({ theme }) => theme.colors.white};
  width: 100%;
  color: #434343;
  font-size: ${rem(12)};

  display: flex;
  flex-direction: column;
  gap: 4px;
`

export const ProductCategory = styled.div`
  width: 100%;

  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.customTokens.text.darkGray};

  font-size: ${rem(14)};

  .ant-divider {
    margin: 0 4px;
  }

  strong {
    font-weight: 600;
  }
`

export const DisplayName = styled(Typography.Text)`
  width: 100%;
  font-weight: 700;
  font-size: ${rem(18)};

  color: #434343;
`

export const SkeletonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  margin-bottom: 32px;
`

export const FallbackContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #f6f6f6;

  height: ${rem(152)};
  width: ${rem(174)};
`

export const FallbackIcon = styled(CameraOutlined)`
  font-size: 34px;
  color: ${({ theme }) => theme.colors.darkGreen};
`
