import { QueryFunctionContext, useQuery } from '@tanstack/react-query'

import type { FilterAndIconsResponse } from '../../types'
import { productKeys } from '../query-keys'

import { api } from '@/services'

async function fetchProductFilterAndIcons({
  queryKey,
}: QueryFunctionContext<ReturnType<(typeof productKeys)['filtersAndIcons']>>) {
  const [{ categoryVtexId }] = queryKey

  const { data } = await api.get<FilterAndIconsResponse>(
    `/specifications/filter_and_icons/${categoryVtexId}`,
  )

  return data
}

export const useProductFilterAndIcons = (categoryVtexId: number, enabled = true) => {
  return useQuery({
    queryKey: productKeys.filtersAndIcons(categoryVtexId),
    queryFn: fetchProductFilterAndIcons,
    keepPreviousData: true,
    enabled: enabled,
  })
}
