import { DownloadOutlined } from '@ant-design/icons'
import type { ButtonProps } from 'antd'

import * as S from './styles'

interface DownloadImagePackButtonProps extends ButtonProps {
  handleDownload: () => void
}

export const DownloadImagePackButton = ({
  handleDownload,
  ...rest
}: DownloadImagePackButtonProps) => {
  return (
    <S.Button type="default" icon={<DownloadOutlined />} onClick={handleDownload} {...rest}>
      Download Pack de Imagens
    </S.Button>
  )
}
