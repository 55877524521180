import { useMutation, useQueryClient } from '@tanstack/react-query'

import { productKeys } from '../..'

import { api } from '@/services'

export interface ImagePackMutation {
  productId: string
  skuId: string
  imagePack: Array<{
    name: string
    main: boolean
    order: number
  }>
  saveAsDraft: boolean
}

export const useImagePackMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (data: ImagePackMutation) => {
      return api.patch(`/skus/${data.skuId}/sku_files?draft=${data.saveAsDraft}`, data.imagePack)
    },
    onSettled: (_, __, { productId }) =>
      queryClient.invalidateQueries(productKeys.detail(productId)),
  })
}
