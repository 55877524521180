import { rem } from 'polished'
import styled from 'styled-components'

export const SpecCard = styled.div`
  padding: 8px;
  background-color: white;

  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 1;
  height: fit-content;

  border: 1px solid #d9d9d9;
  border-radius: 4px;

  strong {
    font-size: ${rem(14)};
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
`
