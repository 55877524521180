import { useQuery } from '@tanstack/react-query'

import { SpecificationsFieldTypes } from '../../types'
import { specificationKeys } from '../query-keys'

import { api } from '@/services'

export const fetchSpecificationsFieldTypes = async () => {
  const { data } = await api.get<SpecificationsFieldTypes[]>('/specifications/field_types')

  return data
}

export const useSpecificationsFieldTypes = () => {
  return useQuery({
    queryKey: specificationKeys.types(),
    queryFn: fetchSpecificationsFieldTypes,
    keepPreviousData: true,
  })
}
