import { Button, Tag, Typography, Input as AntdInput } from 'antd'
import { rem } from 'polished'
import styled from 'styled-components'

export const CloseButton = styled(Button)`
  color: ${({ theme }) => theme.colors.darkGreen};
  border: none;

  :hover {
    color: rgba(94, 163, 32, 0.6);
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  gap: 16px;

  .ant-typography {
    line-height: initial;
  }

  ::-webkit-scrollbar {
    background-color: #fff;
    width: 16px;
  }

  ::-webkit-scrollbar-track {
    background-color: #fff;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
    border: 4px solid #fff;
  }

  ::-webkit-scrollbar-button {
    display: none;
  }
`

export const Text = styled(Typography.Text)`
  font-size: 14px;

  strong {
    font-weight: 700;
    color: #303030d9;
  }
`

export const CardsContainer = styled.div`
  display: flex;
  gap: 16px;
`

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  height: 57px;

  padding: 14px;
  border-radius: 4px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
`

export const BoldText = styled(Typography.Text)`
  font-size: 18px;
  font-weight: 700;

  color: #434343;
`

export const CardText = styled(Typography.Text)`
  font-size: 12px;

  color: #434343;
`

export const Content = styled.div`
  display: flex;
  gap: 12px;
`

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;

  max-width: ${rem(132)};
  max-height: ${rem(132)};
  border-radius: 6px;

  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
  background-color: #fff;
`

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  flex: 1;
`

export const StatusContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  padding-bottom: 8px;
`

export const SummaryContainer = styled.div`
  width: 100%;
  height: 100%;

  max-height: 250px;
  overflow-y: auto;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);

  padding: ${rem(14)};
  border-radius: 6px;
  gap: 8px;

  li {
    line-height: normal;
  }
`

export const Summary = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  width: 100%;
  color: #434343;
  font-size: ${rem(14)};

  display: flex;
  flex-direction: column;
  gap: 4px;

  line-height: initial;
`

export const ProductCategory = styled.div`
  width: 100%;

  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.customTokens.text.darkGray};

  font-size: ${rem(14)};
  line-height: initial;

  .ant-divider {
    margin: 0 4px;
  }
`

export const DisplayName = styled(Typography.Text)`
  width: 100%;
  font-weight: 700;
  font-size: ${rem(16)};

  color: #434343;
`

export const SketchTag = styled(Tag)`
  display: flex;
  align-items: center;
  justify-content: center;

  height: ${rem(21)};
  width: fit-content;

  color: ${({ theme }) => theme.colors.matteBlue};
  border: 1px solid ${({ theme }) => theme.colors.matteBlue};
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.white};

  font-size: ${rem(12)};
`

export const PriceArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
`

export const PriceContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

export const PriceText = styled(Typography.Text)`
  font-size: 12px;
  color: #6c6c6c;

  strong {
    font-weight: 700;
  }
`

export const PricesContainer = styled.div`
  display: flex;
  gap: 16px;
`

export const Input = styled(AntdInput)`
  .ant-input,
  &.ant-input {
    height: 32px;
  }
`

export const PriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  .ant-form-item {
    margin: 0;
  }
`
