import { Form as AntdForm } from 'antd'
import styled from 'styled-components'

export const Form = styled(AntdForm)`
  .ant-form-item {
    flex: 1;
    margin-bottom: 10px;
  }

  .ant-form-item-label > label {
    color: #6c6c6c;
  }

  .ant-input {
    height: 40px;
  }

  .ant-select-single.ant-select-lg .ant-select-selector {
    font-size: 14px;
  }
`

export const Row = styled.div`
  display: flex;

  gap: 22px;
`
