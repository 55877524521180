import { useState } from 'react'

import { PlusOutlined } from '@ant-design/icons'
import { Button, Form, Space } from 'antd'
import { toast } from 'sonner'
import { shallow } from 'zustand/shallow'

import * as S from './styles'

import { CategoryFilters, useCategoryFilters } from '@/app'
import { getActiveFiltersList, removeActiveFilter, useToggle } from '@/common'
import { ActiveFiltersList, CustomPagination, FilterSidebar, LoadingSpinner } from '@/components'
import {
  CategoriesModal,
  CategoriesTable,
  CategoryType,
  CreateCategoriesModal,
  useCategories,
  useChangeCategoryVisibiltyMutation,
} from '@/features/BG'
import { CategoriesFilterForm } from '@/features/users'
import { PageLayout } from '@/layouts'

const CategoriesPage = () => {
  const [form] = Form.useForm()

  const changeCategoryVisibilityMutation = useChangeCategoryVisibiltyMutation()

  const [isCreateCategoryModalOpen, toggleCreateCategoryModal] = useToggle(false)
  const [isEditCategoriesModalOpen, toggleEditCategoriesModal] = useToggle(false)
  const [isFilterDrawerOpen, toggleFilterDrawer] = useToggle(false)
  const [selectedCategory, setSelectedCategory] = useState<CategoryType | undefined>(undefined)

  const { categoriesFilters, setFilters, resetCategoriesFilters } = useCategoryFilters(
    (state) => ({
      categoriesFilters: state.filters,
      setFilters: state.setFilters,
      resetCategoriesFilters: state.resetFilters,
    }),
    shallow,
  )

  const {
    data: categoriesData,
    isLoading: isCategoriesLoading,
    isFetching: isCategoriesFetching,
  } = useCategories(categoriesFilters)

  function handleFilter() {
    const values = form.getFieldsValue()

    setFilters({ ...values, page: 1 })
  }

  function submitFilter() {
    form.submit()
    toggleFilterDrawer()
  }

  function handleClearFilters() {
    form.resetFields()

    form.setFieldsValue({
      product_type_name: undefined,
    })

    resetCategoriesFilters()
    toggleFilterDrawer()
  }

  function handlePagination(page: number, pageSize?: number) {
    setFilters({ page, per_page: pageSize })
  }

  function handleRemoveFilter(filterKey: keyof CategoryFilters, filterValue?: string) {
    const updatedFilters = removeActiveFilter<CategoryFilters>({
      activeFilters: categoriesFilters,
      filterKey,
      filterValue,
    })

    setFilters({ ...updatedFilters })
  }

  function handleEditCategories(category: CategoryType | undefined) {
    setSelectedCategory(category)
    toggleEditCategoriesModal()
  }

  function handleChangeStatus(category: CategoryType, status: boolean) {
    const promise = changeCategoryVisibilityMutation.mutateAsync({
      category_id: category.id,
      status,
    })

    toast.promise(promise, {
      loading: 'Alterando status...',
      success: (
        <S.ToastContainer>
          <S.CircleOutlined />

          <div>
            Status alterado com <span style={{ fontWeight: 'bold' }}>sucesso</span>!
          </div>
        </S.ToastContainer>
      ),
      className: 'toast-sucess',
      error: 'Falha ao alterar status. Tente novamente mais tarde.',
    })
  }

  const PageHeader = () => {
    return (
      <S.Header>
        <S.TitleContainer>
          <S.Title level={4}>Categorias</S.Title>

          <S.Divider thickness={2} type="vertical" />

          {categoriesData && (
            <S.TotalCategoriesContent>
              <S.TotalCategoriesValue>{categoriesData.total}</S.TotalCategoriesValue>
              {categoriesData.total > 1 ? 'encontrados' : 'encontrado'}
            </S.TotalCategoriesContent>
          )}

          {(isCategoriesLoading || isCategoriesFetching) && <LoadingSpinner />}
        </S.TitleContainer>

        <Space>
          <Button type="primary" icon={<PlusOutlined />} onClick={toggleCreateCategoryModal}>
            Cadastrar Categoria
          </Button>
        </Space>
      </S.Header>
    )
  }

  return (
    <PageLayout
      title="Categorias"
      sidebar={
        <FilterSidebar
          isFilterDrawerOpen={isFilterDrawerOpen}
          onClose={toggleFilterDrawer}
          filterFooter={
            <S.FooterSidebar>
              <S.CleanButton onClick={handleClearFilters}>Limpar</S.CleanButton>

              <Button type="primary" htmlType="submit" onClick={submitFilter}>
                Filtrar
              </Button>
            </S.FooterSidebar>
          }
        >
          <CategoriesFilterForm onSubmit={handleFilter} form={form} />
        </FilterSidebar>
      }
    >
      {isCreateCategoryModalOpen && (
        <CreateCategoriesModal
          isOpen={isCreateCategoryModalOpen}
          onClose={toggleCreateCategoryModal}
        />
      )}

      {isEditCategoriesModalOpen && (
        <CategoriesModal
          isOpen={isEditCategoriesModalOpen}
          onClose={() => handleEditCategories(undefined)}
          data={selectedCategory as CategoryType}
        />
      )}

      <S.Container>
        <PageHeader />

        <ActiveFiltersList
          filtersList={getActiveFiltersList(categoriesFilters)}
          removeFilter={handleRemoveFilter}
          cleanFiltersList={handleClearFilters}
        />

        <CategoriesTable
          data={categoriesData?.categories || []}
          isLoading={isCategoriesLoading}
          handleEditCategories={handleEditCategories}
          handleChangeCategoryStatus={handleChangeStatus}
        />

        <S.PaginationContainer>
          <CustomPagination
            scrollToTop
            page={categoriesFilters.page || 1}
            pageSize={categoriesFilters.per_page || 20}
            totalItems={categoriesData?.total || 0}
            totalPages={categoriesData?.total_pages || 1}
            isLoading={isCategoriesLoading}
            changePageValue={(page, pageSize) => handlePagination(page, pageSize)}
          />
        </S.PaginationContainer>
      </S.Container>
    </PageLayout>
  )
}

export { CategoriesPage }
