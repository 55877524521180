import { useInfiniteQuery } from '@tanstack/react-query'

import { NotificationList } from '../types'
import { notificationsKeys } from './query-keys'

import { api } from '@/services'

const DEFAULT_PAGE = 1

export async function fetchNotificationsList({ pageParam = 1 }) {
  const page = pageParam || DEFAULT_PAGE

  const { data } = await api.get<NotificationList>(`/notifications?per_page=20&page=${page}`)

  return data
}

export const useNotificationsList = () => {
  return useInfiniteQuery({
    queryKey: notificationsKeys.list(),
    queryFn: fetchNotificationsList,
    getNextPageParam: (lastPage) => {
      const isLastPage = lastPage.page === lastPage.total_pages

      if (!isLastPage) {
        return lastPage.page + 1
      }

      return null
    },
    keepPreviousData: true,
  })
}
