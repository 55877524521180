import { BsQuestionLg } from 'react-icons/bs'

import { rem } from 'polished'
import styled from 'styled-components'

export const InputsRowContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;

  width: 100%;
  height: fit-content;

  .ant-form-item {
    flex: 1;
  }

  .ant-select,
  input {
    height: ${rem(40)};
  }

  .ant-form-item-label {
    padding: 0 0 4px;
  }
`

export const TooltipContainer = styled.div`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.customTokens.input.label};
  gap: 4px;
`

export const TooltipIcon = styled(BsQuestionLg)`
  padding: 1px;
  font-size: 14px;

  border-radius: 99999999px;

  background-color: ${({ theme }) => theme.colors.brand};
  color: ${({ theme }) => theme.colors.white};
`

export const InputsColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;

  .ant-form-item-label {
    padding: 0 0 4px;
  }

  .ant-form-item {
    flex: 1;
  }

  .tooltipIcon {
    color: ${(props) => props.theme.colors.brand};
  }

  .linkInputs {
    height: ${rem(40)};
  }
`

export const Label = styled.span`
  display: flex;
  align-items: center;
  gap: 4px;
  color: ${(props) => props.theme.customTokens.input.label};

  > span {
    font-size: 10px;
  }
`

export const InputName = styled.span``
