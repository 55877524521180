import { Form, FormInstance, Input, Select } from 'antd'

import * as S from './styles'

import { filtersRemap, specificationsRemap } from '@/common'
import { useProductTypeGroupsField, useProductTypes } from '@/features/BG'
import { useSpecificationsFieldTypes } from '@/features/specifications/queries'

export type EditSpecificationsFormData = {
  field_type_id: number
  product_type: number
  field_group_id: number
  name: string
  position: number
}

interface EditSpecificationsFormProps {
  onEdit: (data: EditSpecificationsFormData) => void
  form: FormInstance
  isLoading: boolean
}

const EditSpecificationsForm = ({ onEdit, isLoading, form }: EditSpecificationsFormProps) => {
  const selectedProductType = Form.useWatch('product_type', form)

  const { data: specificationsFieldType } = useSpecificationsFieldTypes()
  const { data: productTypes } = useProductTypes({
    page: 1,
    per_page: 100,
  })
  const { data: groupSpecifications } = useProductTypeGroupsField(selectedProductType)

  const specificationsFieldTypeOptions = specificationsFieldType?.map((value) => ({
    value: value.id,
    label: (specificationsRemap.get(value.name) as string) || value.name,
  }))

  const productTypeOptions = productTypes?.product_types?.map((value) => ({
    value: value.vtex_id,
    label: (filtersRemap.get(value.name) as string) || value.name,
  }))

  const GroupSpecifications = groupSpecifications?.map((value) => ({
    value: value.id,
    label: value.name,
  }))

  // useEffect(() => {
  //   const selectedGroupSpecification = groupSpecifications?.find(
  //     (specification) => specification.id === selectedGroupsSpecifications,
  //   )?.name

  //   const timeOut = setTimeout(() => {
  //     if (selectedGroupSpecification === undefined) {
  //       return form.setFieldValue('field_group_id', '')
  //     }
  //   }, 100)

  //   return () => clearTimeout(timeOut)
  // }, [form, groupSpecifications, selectedGroupsSpecifications])

  return (
    <S.Form form={form} onFinish={onEdit} disabled={isLoading} layout="vertical" requiredMark>
      <S.Row>
        <Form.Item
          name={'field_type_id'}
          label={'Tipo de Campo'}
          rules={[{ required: true, message: 'Campo obrigatório' }]}
        >
          <Select size="large" options={specificationsFieldTypeOptions} disabled />
        </Form.Item>

        <Form.Item
          name={'product_type'}
          label={'Tipo de Produto'}
          rules={[{ required: true, message: 'Campo obrigatório' }]}
        >
          <Select size="large" options={productTypeOptions} disabled />
        </Form.Item>

        <Form.Item
          name={'field_group_id'}
          label={'Grupo de Especificações'}
          rules={[{ required: true, message: 'Campo obrigatório' }]}
        >
          <Select size="large" options={GroupSpecifications} disabled />
        </Form.Item>
      </S.Row>

      <Form.Item
        name={'name'}
        label={'Nome'}
        rules={[{ required: true, message: 'Campo obrigatório' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name={'position'}
        label={'Posição do Campo'}
        rules={[
          {
            required: true,
            message: 'Campo obrigatório',
          },
          {
            pattern: /[0-9]/,
            message: 'Este campo aceita apenas valores númericos!',
          },
          {
            min: 0,
            message: 'Este campo aceita apenas valores positivos!',
            validator: (_, value) => {
              if (Number(value) < 0) {
                return Promise.reject('Este campo aceita apenas valores positivos!')
              }

              return Promise.resolve()
            },
          },
        ]}
      >
        <Input type="number" min={0} />
      </Form.Item>
    </S.Form>
  )
}

export { EditSpecificationsForm }
