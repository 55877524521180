import { CloseOutlined } from '@ant-design/icons'

import * as S from './styles'

type BgRegistrationDrawerProps = {
  isOpen: boolean
  onClose: () => void
}

const BgDataDrawer = ({ isOpen, onClose }: BgRegistrationDrawerProps) => {
  return (
    <S.Drawer
      title="Como preencher os campos de cadastro do BG?"
      placement="right"
      onClose={onClose}
      open={isOpen}
      closable={false}
      extra={<CloseOutlined onClick={onClose} />}
      footer={<S.CloseButton onClick={onClose}>Fechar</S.CloseButton>}
    >
      <S.Container>
        <S.DescriptionContent>
          <S.DescriptionTitle>1. Campo Categoria:</S.DescriptionTitle>

          <S.DescriptionText>
            Campo select, onde o usuário poderá escolher dentre as opções de categoria do produto
          </S.DescriptionText>
        </S.DescriptionContent>

        <S.DescriptionContent>
          <S.DescriptionTitle>2. Campo Modelo:</S.DescriptionTitle>

          <S.DescriptionText>
            Campo descritivo, onde o usuário poderá preencher manualmente, seguindo as regras
            determinadas. Ex: número alfa numérico separado por hífens A515-54G-79TC
          </S.DescriptionText>
        </S.DescriptionContent>

        <S.DescriptionContent>
          <S.DescriptionTitle>3. Campo Hero Image:</S.DescriptionTitle>

          <S.DescriptionText>
            O usuário deverá preencher nesse campo, a URL do caminho da imagem que estão armazenadas
            no site Parceiros Acer, exemplo:{' '}
            <S.DescriptionLink>
              https://www.parceirosacer.com.br/Conteudos-Especiais/Notebooks/Predator-Helios-
              Neo/A515-54G-79TC/A515-54G-79TC.jpg
            </S.DescriptionLink>
          </S.DescriptionText>
        </S.DescriptionContent>

        <S.DescriptionContent>
          <S.DescriptionTitle>4. Campo Dealer Page:</S.DescriptionTitle>

          <S.DescriptionText>
            O usuário deverá preencher nesse campo, a URL do caminho da pasta onde se encontra os
            arquivos da dealer page que estão armazenados no site Parceiros Acer, exemplo:{' '}
            <S.DescriptionLink>
              https://www.parceirosacer.com.br/Conteudos-Especiais/Notebooks/Predator-Helios-
              Neo/PHN16-71-76PL/dealer
            </S.DescriptionLink>
          </S.DescriptionText>
        </S.DescriptionContent>

        <S.DescriptionContent>
          <S.DescriptionTitle>5. Pack de imagens: </S.DescriptionTitle>

          <S.DescriptionText>
            O usuário deverá preencher nesse campo, a URL do caminho do arquivo zip das imagens do
            BG, que estão armazenados no site Parceiros Acer, exemplo:{' '}
            <S.DescriptionLink>
              https://www.parceirosacer.com.br/Conteudos-Especiais/Notebooks/Predator-Helios-
              Neo/A515-54G-79TC/Pack_Imagens.zip
            </S.DescriptionLink>
          </S.DescriptionText>
        </S.DescriptionContent>
      </S.Container>
    </S.Drawer>
  )
}

export { BgDataDrawer }
