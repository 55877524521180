import { QueryFunctionContext, useQuery } from '@tanstack/react-query'

import { CategoriesListResponse } from '../..'
import { categoryKeys } from '../query-keys'

import { CategoryFilters } from '@/app'
import { generateSearchParams } from '@/common'
import { api } from '@/services'

async function fetchCategories({
  queryKey,
}: QueryFunctionContext<ReturnType<(typeof categoryKeys)['list']>>) {
  const [{ filters }] = queryKey

  const { page, per_page, ...activeFilters } = filters

  const params = generateSearchParams(activeFilters)

  const { data } = await api.get<CategoriesListResponse>(
    `/categories?per_page=${per_page}&page=${page}&${params}`,
  )

  return data
}

export const useCategories = (
  filters: CategoryFilters = {
    page: 1,
    per_page: 20,
  },
  enabled = true,
) => {
  return useQuery({
    queryKey: categoryKeys.list(filters),
    queryFn: fetchCategories,
    keepPreviousData: true,
    enabled,
  })
}
