import { QueryFunctionContext, useQuery } from '@tanstack/react-query'

import { ProductTemplatesListResponse } from '../../../types'
import { templateKeys } from '../../query-keys'

import { api } from '@/services'

async function fetchProductTypeTemplate({
  queryKey,
}: QueryFunctionContext<ReturnType<(typeof templateKeys)['list']>>) {
  const [{ productTypeVtexId }] = queryKey

  const { data } = await api.get<ProductTemplatesListResponse>(
    `/templates?product_type_vtex_id=${productTypeVtexId}`,
  )

  return data
}

export const useProductTypeTemplates = (productTypeVtexId: number, enabled = true) => {
  return useQuery({
    queryKey: templateKeys.list(productTypeVtexId),
    queryFn: fetchProductTypeTemplate,
    keepPreviousData: true,
    enabled: enabled,
  })
}
