import { Button, Form } from 'antd'
import { toast } from 'sonner'

import { CategoriesForm, type CategoriesFormData } from '../..'
import * as S from './styles'

import { ApiError, toastError } from '@/common'
import { BaseModal } from '@/components'
import { createCategoriesErrorMessages, useCreateCategoriesMutation } from '@/features/BG/mutations'

type CreateCategoriesModalProps = {
  isOpen: boolean
  onClose: () => void
}

const CreateCategoriesModal = ({ isOpen, onClose }: CreateCategoriesModalProps) => {
  const [form] = Form.useForm<CategoriesFormData>()
  const createCategoriesMutation = useCreateCategoriesMutation()

  async function handleCreateCategories(data: CategoriesFormData) {
    try {
      const promise = createCategoriesMutation.mutateAsync({
        name: data.name,
        keywords: data.similarWords,
        title: data.pageTitle,
        description: data.description,
        google_global_category_id: data.globalCategory,
        product_type_vtex_id: data.productType,
        anymarket_id: data.anymarket_id,
      })

      toast.promise(promise, {
        loading: 'Cadastrando categoria...',
        success: () => {
          onClose()

          return (
            <S.ToastContainer>
              <S.CircleOutlined />

              <div>
                Categoria <span style={{ fontWeight: 'bold' }}>criada</span> com{' '}
                <span style={{ fontWeight: 'bold' }}>sucesso</span>!
              </div>
            </S.ToastContainer>
          )
        },
        className: 'toast-sucess',
        error: (error: ApiError) =>
          toastError({
            error,
            errorMessages: createCategoriesErrorMessages,
            defaultMessage: 'Erro ao criar categoria.',
          }),
      })
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <BaseModal
      open={isOpen}
      onCancel={onClose}
      title={'Cadastrar Categoria'}
      width={717}
      footer={
        <>
          <S.CancelButton
            type="link"
            onClick={onClose}
            disabled={createCategoriesMutation.isLoading}
          >
            Cancelar
          </S.CancelButton>

          <Button
            type="primary"
            htmlType="submit"
            onClick={() => form.submit()}
            disabled={createCategoriesMutation.isLoading}
          >
            Cadastrar
          </Button>
        </>
      }
    >
      <CategoriesForm
        form={form}
        onSubmit={handleCreateCategories}
        isSubmitting={createCategoriesMutation.isLoading}
      />

      <S.Text>Os campos de * são de preenchimento obrigatório</S.Text>
    </BaseModal>
  )
}

export { CreateCategoriesModal }
