import { CheckCircleOutlined } from '@ant-design/icons'
import { Button, Typography } from 'antd'
import { rem } from 'polished'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
`

export const StepDescription = styled(Typography.Text)`
  color: #00000073;
  font-size: ${rem(14)};
`

export const Subtitle = styled.h3`
  font-weight: 700;
  font-size: 16px;
  padding-top: 8px;
`

export const CancelButton = styled(Button)`
  color: ${({ theme }) => theme.colors.darkGreen};
`

export const BackButton = styled(Button)`
  color: ${({ theme }) => theme.colors.darkGreen};
  border: 1px solid ${({ theme }) => theme.colors.darkGreen};
`

export const ToastContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

export const CircleOutlined = styled(CheckCircleOutlined)`
  display: flex;
  align-items: center;
  justify-content: center;

  color: #fff;
  width: 14px;
  height: 14px;
  border-radius: 99%;
  background-color: #80c343;
`
