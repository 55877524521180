import { QueryFunctionContext, useQuery } from '@tanstack/react-query'

import { bomKeys } from '../query-keys'

import { BOMResult } from '@/features/users'
import { api } from '@/services'

const fetchBom = async ({
  queryKey,
}: QueryFunctionContext<ReturnType<(typeof bomKeys)['list']>>) => {
  const [{ product_model }] = queryKey

  const { data } = await api.get<BOMResult>(`/bom/${product_model}`)

  return data
}

export const useBOM = (product_model: string, enabled = true) => {
  return useQuery({
    queryKey: bomKeys.list(product_model),
    queryFn: fetchBom,
    keepPreviousData: true,
    enabled: enabled,
    retry: 2,
  })
}
