import { Form, Input } from 'antd'
import * as S from './styles'

export const BgEditGeneralInformationForm = () => {
  return (
    <S.Container>
      <Form.Item
        name="model"
        label={<S.Label>Modelo</S.Label>}
        rules={[{ required: true, message: 'Insira o modelo do produto.' }]}
      >
        <Input placeholder="Ex: A515-54G-79TC" />
      </Form.Item>

      <Form.Item
        name="family"
        label={<S.Label>Familia</S.Label>}
        rules={[{ required: true, message: 'Insira a familia do produto.' }]}
      >
        <Input placeholder="Ex: Acer-Nitro-5" />
      </Form.Item>
    </S.Container>
  )
}
