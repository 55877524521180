import { useMutation, useQueryClient } from '@tanstack/react-query'

import { specificationKeys } from '../../queries'
import { SpecificationType } from '../../types'

import { api } from '@/services'

interface EditCategoriesMutation {
  id: string
  field_type_id: number
  product_type_vtex_id: number
  field_group_id: number
  name: string
  position: number
}

export const editSpecificationsErrorMessages = new Map([
  [
    'Error updating specification in VTEX: "The following fields should not be changed: CategoryId, FieldGroupId, IsStockKeepingUnit, FieldTypeId"',
    'Erro ao editar especificação na VTEX: Os seguintes valores não podem ser editados "Tipo de Campo", "Tipo de Produto", "Grupo de Especificações"',
  ],
  ['Specification name already exists.', 'Nome da especificação já existe.'],
])

export const useEditSpecificationsMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (data: EditCategoriesMutation) => {
      return api.put(`specifications/${data.id}`, data)
    },
    onMutate: async (editedSpecifications) => {
      await queryClient.cancelQueries(specificationKeys.all)

      const previousSpecifications = queryClient.getQueryData<SpecificationType[]>(
        specificationKeys.lists(),
      )

      if (previousSpecifications) {
        queryClient.setQueriesData<SpecificationType[]>(
          specificationKeys.lists(),
          (specificationList) => {
            return specificationList?.map((specification) => {
              if (specification.id === editedSpecifications.id) {
                return {
                  ...specification,
                  ...editedSpecifications,
                }
              }

              return specification
            })
          },
        )
      }

      return previousSpecifications
    },
    onError: (error, _, previousSpecifications) => {
      console.log(error)

      if (previousSpecifications) {
        queryClient.setQueryData(specificationKeys.lists(), previousSpecifications)
      }
    },
    onSettled: () => queryClient.invalidateQueries(specificationKeys.all),
  })
}
