import { CheckCircleOutlined } from '@ant-design/icons'
import { rem } from 'polished'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  .ant-form-item {
    margin-bottom: 5px;
  }

  .ant-input {
    height: ${rem(40)};
  }

  .ant-select {
    height: ${rem(40)};
  }

  .ant-input-password {
    height: ${rem(40)};
    .ant-input {
      height: ${rem(30)};
    }
  }
`

export const InputColumns = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 24px;

  .ant-form-item {
    flex: 1;
  }
`

export const PasswordVisualCue = styled.div`
  display: flex;
  flex-direction: column;
  width: ${rem(334)};
  height: ${rem(175)};
  align-items: center;
  gap: 8px;
  padding: 8px;
  border-radius: 4px;
  background-color: rgba(128, 195, 67, 0.1);
  border: 1px solid ${({ theme }) => theme.colors.brand};
`

export const Icon = styled(CheckCircleOutlined)`
  color: ${({ theme }) => theme.colors.darkGreen};
`

export const TipTitleContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  line-height: 19px;
`

export const TipTitle = styled.span`
  font-size: 12px;
  font-weight: 400;
  color: #404040;
`

export const TipContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  line-height: 19px;
`

export const Tip = styled.span`
  font-size: 12px;
  font-weight: 700;
  color: #404040;
`

export const Label = styled.span`
  display: flex;
  align-items: center;
  gap: 4px;
  color: ${(props) => props.theme.customTokens.input.label};

  > span {
    padding-top: 2px;
    font-size: 10px;
  }
`
