import { useMutation, useQueryClient } from '@tanstack/react-query'

import { productKeys } from '../../queries'
import { ProductType } from '../../types'

import { api } from '@/services'

interface EditProductTypeMutation {
  id: string
  name: string
  global_category_id: number
}

export const editProductTypeErrorMessages = new Map([['Error value', 'Error!']])

export const useEditProductTypeMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (data: EditProductTypeMutation) => {
      return api.patch(`/product_types/${data.id}`, data)
    },
    onMutate: async (editedProductTypes) => {
      await queryClient.cancelQueries(productKeys.all)

      const previousProductTypes = queryClient.getQueriesData<ProductType[]>(
        productKeys.productTypes(),
      )

      if (previousProductTypes) {
        queryClient.setQueryData<ProductType[]>(productKeys.productTypes(), (productTypeList) => {
          return productTypeList?.map((productType) => {
            if (productType.id === editedProductTypes.id) {
              return {
                ...productType,
                ...editedProductTypes,
              }
            }

            return productType
          })
        })
      }

      return previousProductTypes
    },
    onError: (error, _, previousProductTypes) => {
      console.log(error)

      if (previousProductTypes) {
        queryClient.setQueryData(productKeys.productTypes(), previousProductTypes)
      }
    },
    onSettled: () => queryClient.invalidateQueries(productKeys.all),
  })
}
