import { CloseOutlined } from '@ant-design/icons'
import { Button, Input } from 'antd'
import styled from 'styled-components'

export const Container = styled.div`
  .ant-input-suffix {
    transition: all 0 ease-in-out;

    :hover {
      filter: brightness(1.1);
    }
  }

  .ant-input-group-wrapper .ant-input-search {
    height: 20px;
  }

  .ant-input-search-button {
    border-left: none;
  }

  .ant-input-affix-wrapper {
    color: ${({ theme }) => theme.colors.darkGreen};
    border-color: ${({ theme }) => theme.colors.darkGreen};
    border-right: none;
    box-shadow: none;

    :hover {
      border-right: none;
    }
  }

  .ant-input-search
    > .ant-input-group
    > .ant-input-group-addon:last-child
    .ant-input-search-button:not(.ant-btn-primary) {
    border-color: ${({ theme }) => theme.colors.darkGreen};
    color: ${({ theme }) => theme.colors.darkGreen};
  }
`

export const InputTrigger = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 30px;
  height: 30px;

  border-color: ${({ theme }) => theme.colors.darkGreen};
  color: ${({ theme }) => theme.colors.darkGreen};
  background-color: #fff;
`

export const CloseIcon = styled(CloseOutlined)`
  transition: all 0.2s ease;
  cursor: pointer;
`

export const SearchInput = styled(Input.Search)`
  width: 330px;
  height: 30px;

  transition: all 0.6s ease-in-out;
`
