import { useQuery } from '@tanstack/react-query'

import { marketplaceKeys } from '..'

import { IMarketplaceStatus } from '../../types'

import { api } from '@/services'

export const fetchMarketplaceStatus = async () => {
  const { data } = await api.get<IMarketplaceStatus>('/health/integrations')

  return data
}

export const useMarketplaceStatus = () => {
  const refreshInterval = Number(import.meta.env.VITE_VTEX_STATUS_REFRESH_INTERVAL) || 60000 // 1 minute

  return useQuery({
    queryKey: marketplaceKeys.status(),
    queryFn: fetchMarketplaceStatus,
    keepPreviousData: true,
    refetchInterval: refreshInterval,
    // refetchIntervalInBackground: true,
  })
}
