import { Typography } from 'antd'
import styled from 'styled-components'

type DotDivProps = {
  $status?: string
}

export const StepsContainer = styled.div`
  overflow-x: auto;

  margin-bottom: 32px;

  .ant-steps.ant-steps-dot .ant-steps-item-tail::after {
    height: 2px;
    margin-inline-start: 15px;
    margin-top: 4px;
  }

  .ant-steps.ant-steps-label-vertical .ant-steps-item-content {
    text-align: left;
  }

  .ant-steps.ant-steps-dot .ant-steps-item-icon {
    border: 1px solid transparent;
  }

  .ant-steps .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
    background: none;
  }

  ::-webkit-scrollbar {
    background-color: #fff;
    width: 16px;
  }

  ::-webkit-scrollbar-track {
    background-color: #fff;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
    border: 4px solid #fff;
  }

  ::-webkit-scrollbar-button {
    display: none;
  }
`

export const Content = styled.div`
  display: flex;
  margin-left: -64px;
  margin-right: 60px;
`

export const ItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-left: 68px;
  width: 89%;
`

export const DescriptionName = styled(Typography.Text)`
  color: #434343;
  font-size: 12px;
  font-weight: 700;
`

export const DescriptionText = styled(Typography.Text)`
  color: #666;
  font-size: 12px;
  font-weight: 400;
`

export const DescriptionDate = styled(Typography.Text)`
  color: #666;
  font-size: 12px;
  font-weight: 700;
`

export const DotDiv = styled.div<DotDivProps>`
  background: ${({ $status }) => {
    switch ($status) {
      case 'EM RASCUNHO DO PRODUTO':
        return `#945FB980`

      case 'PENDENTE DE ENVIO PARA AGÊNCIA':
        return `#FAAD14`

      case 'PENDENTE DE LIBERAÇÃO DA AGÊNCIA':
        return `#FF6600`

      case 'PENDENTE DE CADASTRO NO PROTHEUS':
        return `#E86452`

      case 'EM RASCUNHO NO E-COMMERCE':
        return `#4A91FD`

      case 'PENDENTE DE PRECIFICAÇÃO':
        return `#004FC7`

      case 'PUBLICADO':
        return `#1E9493`

      case 'DESPUBLICADO':
        return `#666666`

      case 'DESCONTINUADO':
        return `#99999999`

      default:
        return '#99999999'
    }
  }};

  border: ${({ $status }) => ($status === 'Cadastro do BG' ? '1px solid #6B9BE4' : null)};

  width: 15px;
  height: 15px;

  border-radius: 100%;
`
