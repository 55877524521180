import { QueryFunctionContext, useQuery } from '@tanstack/react-query'

import { ProductTypes } from '../../types'
import { productKeys } from '../query-keys'

import { api } from '@/services'
import { ProductTypeFilters } from '@/app'

async function fetchProductTypes({
  queryKey,
}: QueryFunctionContext<ReturnType<(typeof productKeys)['type']>>) {
  const [{ filters }] = queryKey

  const { page, per_page } = filters

  const { data } = await api.get<ProductTypes>(`/product_types?per_page=${per_page}&page=${page}`)

  return data
}

export const useProductTypes = (
  filters: ProductTypeFilters = {
    page: 1,
    per_page: 20,
  },
) => {
  return useQuery({
    queryKey: productKeys.type(filters),
    queryFn: fetchProductTypes,
    keepPreviousData: true,
  })
}
