import { Typography } from 'antd'
import { rem } from 'polished'
import styled from 'styled-components'

export const TopContainer = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${({ theme }) => theme.base.colorBgLayout};
  border-radius: 5px;
  padding: 8px;
  border: 1px solid ${({ theme }) => theme.base.colorBorderSecondary};
  gap: 8px;
`

export const StepDescription = styled(Typography.Text)`
  color: #00000073;
  font-size: ${rem(14)};
`

export const Container = styled(Typography.Text)`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: ${rem(32)};
`

export const FirstInfoContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  gap: 8px;
  width: ${rem(110)};
  strong {
    font-size: ${rem(14)};
    color: ${({ theme }) => theme.customTokens.tab.selectedColor};
    font-weight: 700;
  }
  span {
    font-size: ${rem(12)};
    color: ${({ theme }) => theme.customTokens.divider.gray};
  }
`
export const DividerContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  gap: 8px;
  .ant-divider {
    margin: 4px 0;
    height: ${rem(40)};
    color: ${({ theme }) => theme.customTokens.divider.lightGray};
  }
  strong {
    font-size: ${rem(14)};
    color: ${({ theme }) => theme.customTokens.tab.selectedColor};
    font-weight: 700;
  }
  span {
    font-size: ${rem(12)};
    color: ${({ theme }) => theme.customTokens.divider.gray};
  }
`

export const SecondInfoContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  gap: 8px;
  width: ${rem(220)};

  strong {
    font-size: ${rem(14)};
    color: ${({ theme }) => theme.customTokens.tab.selectedColor};
    font-weight: 700;
  }
  span {
    font-size: ${rem(12)};
    color: ${({ theme }) => theme.customTokens.divider.gray};
  }
`
export const ThirdInfoContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  gap: 8px;
  width: ${rem(360)};

  strong {
    font-size: ${rem(14)};
    color: ${({ theme }) => theme.customTokens.tab.selectedColor};
    font-weight: 700;
  }
  span {
    font-size: ${rem(12)};
    color: ${({ theme }) => theme.customTokens.divider.gray};
  }
`

export const LogData = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

export const Label = styled.span`
  color: ${({ theme }) => theme.customTokens.input.label};
  font-size: ${rem(14)};
`
export const Text = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${({ theme }) => theme.base.colorBgLayout};
  border-radius: 5px;
  padding: 8px;
  border: 1px solid ${({ theme }) => theme.base.colorBorderSecondary};
  font-size: ${rem(16)};
`
export const Info = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`
