import { Button, Form } from 'antd'
import { toast } from 'sonner'

import { CreateProductTypeForm, CreateProductTypeFormData } from '../../Form'
import * as S from './styles'

import { ApiError, toastError } from '@/common'
import { BaseModal } from '@/components'
import {
  createProductTypeErrorMessages,
  useCreateProductTypeMutation,
} from '@/features/BG/mutations/product-type'

type CreateProductTypeModalProps = {
  isOpen: boolean
  onClose: () => void
}

const CreateProductTypeModal = ({ isOpen, onClose }: CreateProductTypeModalProps) => {
  const [form] = Form.useForm()

  const createProductTypeMutation = useCreateProductTypeMutation()

  async function handleCreateProductType(data: CreateProductTypeFormData) {
    try {
      const promise = createProductTypeMutation.mutateAsync({
        name: data.name,
        global_category_id: data.global_category_id,
      })

      toast.promise(promise, {
        loading: 'Salvando tipo de produto...',
        success: () => {
          onClose()

          return (
            <S.ToastContainer>
              <S.CircleOutlined />

              <div>
                Tipo de Produto <span style={{ fontWeight: 'bold' }}>criado</span> com{' '}
                <span style={{ fontWeight: 'bold' }}>sucesso</span>!
              </div>
            </S.ToastContainer>
          )
        },
        className: 'toast-sucess',
        error: (error: ApiError) =>
          toastError({
            error,
            errorMessages: createProductTypeErrorMessages,
            defaultMessage: 'Erro ao salvar tipo de produto.',
          }),
      })
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <BaseModal
      open={isOpen}
      onCancel={onClose}
      title={'Criar tipo de Produto'}
      width={717}
      footer={
        <>
          <S.CancelButton
            type="link"
            onClick={onClose}
            disabled={createProductTypeMutation.isLoading}
          >
            Cancelar
          </S.CancelButton>

          <Button
            type="primary"
            htmlType="submit"
            onClick={() => form.submit()}
            disabled={createProductTypeMutation.isLoading}
          >
            Salvar
          </Button>
        </>
      }
    >
      <S.Container>
        <CreateProductTypeForm
          form={form}
          onSubmit={handleCreateProductType}
          isSubmitting={createProductTypeMutation.isLoading}
        />
      </S.Container>
    </BaseModal>
  )
}

export { CreateProductTypeModal }
