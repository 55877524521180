import { useQuery, QueryFunctionContext } from '@tanstack/react-query'

import { Timeline, productKeys } from '../..'

import { api } from '@/services'

const fetchTimeline = async ({
  queryKey,
}: QueryFunctionContext<ReturnType<(typeof productKeys)['timeline']>>) => {
  const [{ productId }] = queryKey

  const { data } = await api.get<Timeline[]>(`/timelines/${productId}`)

  return data
}

export const useTimeline = (productId: string) =>
  useQuery({
    queryKey: productKeys.timeline(productId),
    queryFn: fetchTimeline,
    keepPreviousData: true,
  })
