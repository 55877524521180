import { useNavigate } from 'react-router-dom'

import { Button, Form } from 'antd'
import { toast } from 'sonner'

import { BgDataDrawer, BgDataForm, CreateDataBgFormData } from '../../..'
import * as S from './styles'

import { ApiError, toastError, useToggle } from '@/common'
import { BaseModal } from '@/components'
import { Product } from '@/features/BG'
import {
  registerDataBgErrorMessages,
  useRegisterDataBgMutation,
} from '@/features/BG/mutations/data-bg-register'

type BgRegistrationModalProps = {
  isOpen: boolean
  onClose: () => void
  productdata: Product | undefined
}

const BGDataModal = ({ isOpen, onClose, productdata }: BgRegistrationModalProps) => {
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const registerDataBgMutation = useRegisterDataBgMutation()

  const [isBgDataDrawerOpen, toggleBgDataDrawer] = useToggle(false)

  async function handleRegisterBG(data: CreateDataBgFormData) {
    try {
      const promise = registerDataBgMutation.mutateAsync({
        category: data.category as string,
        hero_image: data.hero_image as string,
        model: data.model as string,
        pack: data.pack as string,
        dealer_page: data.dealer_page as string,
        product_id: productdata?.id,
        family: data.family as string,
      })

      toast.promise(promise, {
        loading: 'Completando Cadastro do BG...',
        success: () => {
          navigate('/')

          return (
            <S.ToastContainer>
              <S.CircleOutlined />

              <div>
                BG <span style={{ fontWeight: 'bold' }}>cadastrado</span> com{' '}
                <span style={{ fontWeight: 'bold' }}>sucesso</span>!
              </div>
            </S.ToastContainer>
          )
        },
        className: 'toast-sucess',
        error: (error: ApiError) =>
          toastError({
            error,
            errorMessages: registerDataBgErrorMessages,
            defaultMessage: 'Erro ao completar cadastro do BG.',
          }),
      })
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      {isBgDataDrawerOpen && (
        <BgDataDrawer isOpen={isBgDataDrawerOpen} onClose={toggleBgDataDrawer} />
      )}

      <BaseModal
        open={isOpen}
        onCancel={onClose}
        title={'Cadastrar BG'}
        width={850}
        footer={
          <>
            <S.CancelButton
              type="link"
              onClick={onClose}
              disabled={registerDataBgMutation.isLoading}
            >
              Cancelar
            </S.CancelButton>

            <Button
              type="primary"
              htmlType="submit"
              onClick={() => form.submit()}
              loading={registerDataBgMutation.isLoading}
            >
              Cadastrar
            </Button>
          </>
        }
      >
        <S.Container>
          <S.Text>Todos os campos são de preenchimento obrigatório</S.Text>

          <BgDataForm
            form={form}
            onSubmit={handleRegisterBG}
            isRegistering={registerDataBgMutation.isLoading}
            productSpecification={undefined}
            // productSpecification={productdata?.product_specification}
          />

          <S.ButtonContainer>
            <S.Button type="link" onClick={toggleBgDataDrawer}>
              Dúvidas com o preenchimento do Cadastro?
            </S.Button>
          </S.ButtonContainer>
        </S.Container>
      </BaseModal>
    </>
  )
}

export { BGDataModal }
