import { ReactElement, useCallback, useState } from 'react'

import { LoadingOutlined } from '@ant-design/icons'
import { Image, Divider, Form, GetProp, Spin, Upload, UploadFile, UploadProps, Tooltip } from 'antd'
import { toast } from 'sonner'

import * as S from './styles'

import UploadImage from '@/assets/upload-image.svg'
import type { BgFileUploadData, CreateProductFormValues, UploadStatus } from '@/features/BG/types'
import { api } from '@/services'

type CreateBGFormProps = {
  setImagePackData: React.Dispatch<React.SetStateAction<BgFileUploadData[]>>
  formValues: CreateProductFormValues
  imagePackData: BgFileUploadData[]
  hasInitialValues?: boolean
  uploadStatus: UploadStatus
  setUploadStatus: React.Dispatch<React.SetStateAction<UploadStatus>>
}

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0]

export const UploadImages = ({
  setImagePackData,
  formValues,
  imagePackData,
  hasInitialValues,
  uploadStatus,
  setUploadStatus,
}: CreateBGFormProps) => {
  const form = Form.useFormInstance()
  const [previewOpen, setPreviewOpen] = useState(false)
  const [previewImage, setPreviewImage] = useState('')

  const selectedProductType = formValues?.product_type?.label

  const getBase64 = (file: FileType): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result as string)
      reader.onerror = (error) => reject(error)
    })

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as FileType)
    }

    setPreviewImage(file.url || (file.preview as string))
    setPreviewOpen(true)
  }

  const fetchS3Url = useCallback(async (file: File) => {
    const extension = file.name.split('.').pop()

    const { data } = await api.get<Omit<BgFileUploadData, 'file'>>(
      `/s3/upload/presigned_url?file_type=${extension}`,
    )

    return { ...data, file }
  }, [])

  const handleUploadFile = useCallback(
    async (file: File) => {
      if (uploadStatus === 'uploading') return

      setUploadStatus('uploading')

      try {
        const result = await fetchS3Url(file)

        setImagePackData((oldData) => [...oldData, result])
        setUploadStatus('done')

        toast.success(
          <S.ToastContainer>
            <S.CircleOutlined />

            <div>
              {file.name} <span style={{ fontWeight: 'bold' }}>carregado</span> com{' '}
              <span style={{ fontWeight: 'bold' }}>sucesso</span>
            </div>
          </S.ToastContainer>,
          {
            position: 'bottom-right',
            className: 'toast-sucess',
          },
        )
      } catch (error) {
        console.error('Error uploading file:', error)
        toast.error(`Erro ao carregar arquivo ${file.name}.`)
      }
    },
    [uploadStatus, fetchS3Url, setImagePackData],
  )

  const uploaderProps: UploadProps = {
    // name: 'file',
    multiple: true,
    action: '',
    showUploadList: true,
    maxCount: 10,
    listType: 'picture-card',
    onPreview: handlePreview,
    beforeUpload: (file: File, fileList: UploadFile[]) => {
      const totalFiles = imagePackData.length + fileList.length
      const currentFileIndex = fileList.indexOf(file as any)
      const remainingSlots = Math.max(10 - imagePackData.length, 0)

      // If the total number of files exceeds the limit, ignore the file
      if (totalFiles > 10) {
        if (currentFileIndex >= remainingSlots) {
          // This is the first file that exceeds the limit
          if (currentFileIndex === remainingSlots) {
            const excludedFiles = fileList
              .slice(remainingSlots)
              .map((f) => f.name)
              .join(', ')

            toast.error(
              `Limite de 10 imagens atingido. Os seguintes arquivos não foram adicionados: ${excludedFiles}`,
              {
                position: 'bottom-right',
              },
            )
          }

          return Upload.LIST_IGNORE
        }
      }

      if (imagePackData.length > 0) {
        const hasFile = imagePackData.find((image) => image?.file?.name === file.name)

        if (hasFile) {
          toast.error(`Arquivo ${file.name} já adicionado.`)
          return Upload.LIST_IGNORE
        }
      }

      handleUploadFile(file)
      return false
    },
    onChange: ({ fileList }) => {
      const hasMaximunFiles = fileList.length >= 10

      if (hasMaximunFiles) {
        toast.info('Limite de 10 imagens atingido.')
      }
    },
    onRemove: (file) => {
      let newImagePackData: BgFileUploadData[] = []

      if (hasInitialValues) {
        newImagePackData = imagePackData.filter((image) => image.filename !== file.uid)

        const excludedFiles = form.getFieldValue('excludedFiles') || []

        form.setFieldValue('excludedFiles', [...excludedFiles, file.uid])
      } else {
        if (file.thumbUrl) {
          URL.revokeObjectURL(file.thumbUrl as string)
        }

        newImagePackData = imagePackData.filter((image) => {
          return image?.file?.name !== file.name
        })
      }

      setImagePackData(newImagePackData)
    },
  }

  const ImageRender = (originNode: ReactElement, file: UploadFile) => {
    return (
      <S.UploadedImageContainer>
        {originNode}

        <Tooltip title={file.name}>
          <S.ImageName>{file.name}</S.ImageName>
        </Tooltip>
      </S.UploadedImageContainer>
    )
  }

  return (
    <S.ThirdStepFormContainer
      $uploaderEmpty={imagePackData.length === 0 || uploadStatus === 'uploading'}
    >
      {previewImage && (
        <Image
          src={previewImage}
          wrapperStyle={{ display: 'none' }}
          preview={{
            visible: previewOpen,
            onVisibleChange: (visible) => setPreviewOpen(visible),
            afterOpenChange: (visible) => !visible && setPreviewImage(''),
          }}
        />
      )}

      <S.TitleContainer>
        <S.ProductCategory>
          <span>{selectedProductType || '--'}</span>

          <Divider type="vertical" style={{ margin: 0 }} />

          <strong>{formValues?.category?.label || '--'}</strong>

          <Divider type="vertical" style={{ margin: 0 }} />

          <span>{formValues.ref_id || '--'}</span>
        </S.ProductCategory>

        <S.BGName>{formValues.model}</S.BGName>

        <S.UploaderIntruction>
          Adicione um total de 10 imagens para o produto.{' '}
          <strong>({imagePackData.length}/10)</strong>
        </S.UploaderIntruction>
      </S.TitleContainer>

      <Form.Item
        className="uploadDraggerBox"
        name="bgFile"
        rules={[
          { required: true, message: 'Insira pelo menos uma imagem para o produto.' },
          {
            pattern: new RegExp(`[/.(jpg|png|jpeg)$/i]`),
            message: 'Deve ser um documento de texto.',
          },
        ]}
      >
        <Upload
          accept=".jpg, .png, .jpeg"
          className="uploadDragger"
          itemRender={ImageRender}
          disabled={uploadStatus === 'uploading'}
          defaultFileList={imagePackData.map((image) => {
            let url: string | undefined

            if (!hasInitialValues && image.file) {
              url = URL.createObjectURL(image.file as File)
            }

            return {
              ...image,
              name: (image?.file?.name as string) || image.filename,
              uid: image.filename,
              thumbUrl: url || image.url,
            }
          })}
          {...uploaderProps}
        >
          {(!uploadStatus || uploadStatus === 'done') && (
            <S.UploadStatusContainer $isDone={imagePackData.length >= 10}>
              <img src={UploadImage} alt="upload bg" />
            </S.UploadStatusContainer>
          )}

          {uploadStatus === 'uploading' && (
            <S.LoadingStatusContainer>
              <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
            </S.LoadingStatusContainer>
          )}
        </Upload>
      </Form.Item>
    </S.ThirdStepFormContainer>
  )
}
