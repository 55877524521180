import { useMutation } from '@tanstack/react-query'

import type { CreateProductResponse } from '../../types'

import { api } from '@/services'

export type SaveProductImagePackMutation = {
  images: string[]
  productId: string
  saveAsDraft: boolean
}

export const useSaveProductImagePack = () => {
  return useMutation({
    mutationFn: async ({ images, productId, saveAsDraft }: SaveProductImagePackMutation) => {
      return api.post<CreateProductResponse>(`/skus/sku_files?is_draft=${saveAsDraft}`, {
        images,
        product_id: productId,
      })
    },
  })
}
2
