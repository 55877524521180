import { Fragment, useEffect, useState } from 'react'

import { CloseOutlined, CopyOutlined } from '@ant-design/icons'
import { Button, Divider, Empty, Form, message } from 'antd'

import * as S from './styles'

import { useDebounce } from '@/common'
import { TitleTabs } from '@/components/Layout/PageLayout'
import { useBOM, useScraping } from '@/features/BG/queries'
import { CreateProductFormValues } from '@/features/BG/types'
import type { Fabricantes, ScrapingResult } from '@/features/users'

type SpecificationsHelpDrawerProps = {
  isOpen: boolean
  onClose: () => void
  formValues: CreateProductFormValues
}

type TabKeys = 'bom' | 'fabricante'

const SpecificationsHelpDrawer = ({
  isOpen,
  onClose,
  formValues,
}: SpecificationsHelpDrawerProps) => {
  const [antdToast, antdToastContextHolder] = message.useMessage()
  const [tabActiveKey, setTabActiveKey] = useState<TabKeys>('bom')

  const [form] = Form.useForm()
  const selectedFabricante = Form.useWatch<Fabricantes>('fabricante', form)
  const scrapingSearchValue = Form.useWatch('search', form)
  // const bomSearchValue = Form.useWatch('bom', form)

  const [searchOnChangeValue, setSearchOnChangeValue] = useState('')
  const debouncedScrapingSearch = useDebounce(scrapingSearchValue?.trim(), 400)
  // const debouncedBomSearch = useDebounce(bomSearchValue, 400)

  const {
    data: scraping,
    isFetching: isScrapingLoading,
    isInitialLoading: isScrapingInitialLoading,
    isError,
    isFetched,
    fetchStatus,
  } = useScraping({
    scrapingType: selectedFabricante,
    search: debouncedScrapingSearch,
    enabled: !!debouncedScrapingSearch,
  })

  const {
    data: bom,
    isLoading: isBomLoading,
    isError: isBomError,
  } = useBOM(formValues?.model, !!formValues?.model)

  useEffect(() => {
    if (searchOnChangeValue) {
      form.setFieldsValue({ search: '' })
    }
  }, [form, searchOnChangeValue])

  function handleTabChange(key: string) {
    setTabActiveKey(key as TabKeys)
  }

  function handleSelectChange(value: string) {
    setSearchOnChangeValue(value)
  }

  async function handleCopyRowValue(key: string, value: string) {
    try {
      const resultString = `${key}: ${value}`

      // Use the Clipboard API to copy the text to the clipboard
      await navigator.clipboard.writeText(resultString)

      antdToast.open({
        type: 'success',
        content: 'Copiado!',
        duration: 1,
      })
    } catch (error) {
      console.error('Failed to copy to clipboard:', error)

      antdToast.open({
        type: 'error',
        content: 'Erro ao copiar!',
        duration: 1,
      })
    }
  }

  async function handleCopyFormattedJSON(
    jsonData: { [key: string]: any } | Array<{ [key: string]: any }>,
  ) {
    try {
      // Normalize input to always be an array
      const normalizedData = Array.isArray(jsonData) ? jsonData : [jsonData]

      // Process each object in the array and format it
      const formattedEntries = normalizedData.map((data) => {
        return Object.entries(data)
          .reduce((acc, [key, value]) => {
            if (value !== null) {
              // Convert array values to a comma-separated string
              const formattedValue = Array.isArray(value) ? value.join(', ') : value
              acc.push(`${key}: ${formattedValue}`)
            }
            return acc
          }, [] as string[])
          .join(',\n')
      })

      // Join all formatted object entries, separated by "\n\n" for clarity between objects
      const resultString = formattedEntries.join('\n\n')

      await navigator.clipboard.writeText(resultString)

      antdToast.open({
        type: 'success',
        content: 'Copiado!',
        duration: 1,
      })
    } catch (error) {
      console.error('Failed to copy to clipboard:', error)

      antdToast.open({
        type: 'error',
        content: 'Erro ao copiar!',
        duration: 1,
      })
    }
  }

  const SkeletonPage = () => {
    return (
      <S.SkeletonContainer>
        <S.SkeletonContent>
          <S.SkeletonInput active size="default" />
        </S.SkeletonContent>

        <S.SkeletonContent>
          <S.SkeletonInput active size="small" />
          <S.SkeletonInput active size="small" />
        </S.SkeletonContent>

        <S.SkeletonContent>
          <S.SkeletonInput active size="small" />
          <S.SkeletonInput active size="small" />
        </S.SkeletonContent>

        <S.SkeletonContent>
          <S.SkeletonInput active size="small" />
          <S.SkeletonInput active size="small" />
        </S.SkeletonContent>

        <S.SkeletonContent>
          <S.SkeletonInput active size="small" />
          <S.SkeletonInput active size="small" />
        </S.SkeletonContent>
      </S.SkeletonContainer>
    )
  }

  const productTabItems = [
    {
      key: 'bom',
      label: 'BOM ',
      content: (
        <S.FirstContainer>
          <S.DescriptionText>
            <span>
              <strong>BOM</strong> - Planilha que descreve todos os componentes de um produto.
            </span>
          </S.DescriptionText>
          {/* <Form form={form}>
            <Form.Item name="bom" initialValue={formValues?.model}>
              <S.SearchInput placeholder="Buscar..." defaultValue={formValues?.model} />
            </Form.Item>
          </Form> */}

          <S.ValuesContainer>
            {isBomError && (
              <S.EmptyContainer>
                <Empty
                  description={
                    <span>
                      Nenhum dado encontrado para o modelo: <strong>{formValues?.model}</strong>
                    </span>
                  }
                />
              </S.EmptyContainer>
            )}

            {isBomLoading ? (
              <SkeletonPage />
            ) : (
              bom && (
                <>
                  <S.SearchValueTitle>{formValues?.model}</S.SearchValueTitle>

                  {Object.entries(bom).map(([key, value], index, array) => {
                    if (array.length === 0) {
                      return (
                        <S.EmptyContainer key={`${index}-${crypto.randomUUID()}`}>
                          <Empty description={<span>Nenhum resultado encontrado</span>} />
                        </S.EmptyContainer>
                      )
                    }

                    const isLastItem = index === array.length - 1

                    const isValueArray = Array.isArray(value)

                    let displayValue = value ? value : '-'

                    if (typeof value !== 'string' && !isValueArray) {
                      displayValue = '-'
                    }

                    if (isValueArray) {
                      displayValue = value.join(', ')
                    }

                    return (
                      <>
                        <S.Content key={`${key}-${index}`}>
                          <S.TextKey>{key}:</S.TextKey>
                          <S.TextDescription>{displayValue}</S.TextDescription>

                          <Button
                            type="link"
                            className="copy-button"
                            onClick={() => handleCopyRowValue(key, displayValue as string)}
                          >
                            <CopyOutlined />
                          </Button>
                        </S.Content>

                        {!isLastItem && <Divider style={{ margin: 0 }} />}
                      </>
                    )
                  })}
                </>
              )
            )}
          </S.ValuesContainer>
        </S.FirstContainer>
      ),
    },
    {
      key: 'fabricante',
      label: 'Fabricantes',
      content: (
        <S.SecondContainer>
          <S.DescriptionText>
            <span>
              Busque mais informações sobre o produto através do <strong>Fabricante</strong> e seu{' '}
              <strong>Identificador Único</strong>.
            </span>

            <ul>
              {selectedFabricante === 'intel' && (
                <li>
                  <strong>Intel:</strong>

                  <span>
                    Busque pelo <strong>NÚMERO</strong> de série.
                  </span>

                  <span>
                    Exemplo: <S.ExampleText>i5-7400</S.ExampleText>
                  </span>
                </li>
              )}

              {selectedFabricante === 'amd' && (
                <li>
                  <strong>AMD:</strong>

                  <span>
                    Busque pelo <strong>NOME</strong> específico.
                  </span>

                  <span>
                    Exemplo: <S.ExampleText>AMD Ryzen 7 PRO 8845HS</S.ExampleText>
                  </span>
                </li>
              )}

              {selectedFabricante === 'nvidia' && (
                <li>
                  <strong>Nvidia:</strong>
                  <span>
                    Busque pelo <strong>NOME</strong> da placa de vídeo.
                  </span>
                  <span>
                    Exemplo: <S.ExampleText>ASUS GTX 1650</S.ExampleText>
                  </span>
                </li>
              )}
            </ul>
          </S.DescriptionText>

          <Form layout="vertical" form={form}>
            <S.SearchContent>
              <Form.Item label="Fabricante" name={'fabricante'} className="select">
                <S.Select
                  options={[
                    { value: 'intel', label: 'Intel' },
                    { value: 'amd', label: 'AMD' },
                    { value: 'nvidia', label: 'Nvidia' },
                  ]}
                  onChange={handleSelectChange}
                />
              </Form.Item>

              <Form.Item label="Identificador único" name={'search'} className="input">
                <S.SearchInput
                  placeholder={selectedFabricante ? 'Buscar...' : ''}
                  disabled={!selectedFabricante}
                  allowClear
                />
              </Form.Item>
            </S.SearchContent>
          </Form>

          <S.ValuesContainer>
            {!isScrapingInitialLoading && !isError && !debouncedScrapingSearch && (
              <S.EmptyContainer>
                <Empty description="Por favor, selecione um fabricante e um identificador único para buscar mais dados" />
              </S.EmptyContainer>
            )}

            {isError && (
              <S.EmptyContainer>
                <Empty
                  description={
                    <span>
                      Nenhum dado encontrado para o campo <strong>{debouncedScrapingSearch}</strong>
                    </span>
                  }
                />
              </S.EmptyContainer>
            )}

            {isScrapingInitialLoading || (fetchStatus === 'fetching' && !isFetched) ? (
              <SkeletonPage />
            ) : (
              scraping &&
              !!debouncedScrapingSearch && (
                <>
                  {/* Nvidia */}
                  {Array.isArray(scraping) ? (
                    <>
                      {(scraping as unknown as ScrapingResult[])?.map((item, index) => {
                        return (
                          <Fragment
                            key={`${item?.display_name || debouncedScrapingSearch} - ${index}`}
                          >
                            <S.ListValueTitle>
                              {item?.display_name || debouncedScrapingSearch}
                            </S.ListValueTitle>

                            {Object.entries(item || {})?.map(([key, value], index, array) => {
                              if (array.length === 0) {
                                return (
                                  <span key={`${index}-${crypto.randomUUID()}`}>
                                    Nenhum resultado encontrado
                                  </span>
                                )
                              }

                              const isLastItem = index === array.length - 1

                              return (
                                <>
                                  <S.Content key={`${key}-${index}`}>
                                    <S.TextKey>{key}:</S.TextKey>
                                    <S.TextDescription>{value ? value : '-'}</S.TextDescription>

                                    <Button
                                      type="link"
                                      className="copy-button"
                                      onClick={() => handleCopyRowValue(key, value)}
                                    >
                                      <CopyOutlined />
                                    </Button>
                                  </S.Content>

                                  {!isLastItem && <Divider style={{ margin: 0 }} />}
                                </>
                              )
                            })}
                          </Fragment>
                        )
                      })}
                    </>
                  ) : (
                    <>
                      <S.SearchValueTitle>{debouncedScrapingSearch}</S.SearchValueTitle>

                      {Object.entries(scraping || {})?.map(([key, value], index, array) => {
                        if (array.length === 0) {
                          return (
                            <span key={`${index}-${crypto.randomUUID()}`}>
                              Nenhum resultado encontrado
                            </span>
                          )
                        }

                        const isLastItem = index === array.length - 1

                        return (
                          <>
                            <S.Content key={`${key}-${index}`}>
                              <S.TextKey>{key}:</S.TextKey>
                              <S.TextDescription>{value ? value : '-'}</S.TextDescription>

                              <Button
                                type="link"
                                className="copy-button"
                                onClick={() => handleCopyRowValue(key, value)}
                              >
                                <CopyOutlined />
                              </Button>
                            </S.Content>

                            {!isLastItem && <Divider style={{ margin: 0 }} />}
                          </>
                        )
                      })}
                    </>
                  )}
                </>
              )
            )}
          </S.ValuesContainer>
        </S.SecondContainer>
      ),
    },
  ]

  return (
    <S.Drawer
      title={
        <TitleTabs items={productTabItems} defaultActiveKey={'bom'} onChange={handleTabChange} />
      }
      placement="right"
      onClose={onClose}
      open={isOpen}
      closable={false}
      width={729}
      extra={<CloseOutlined onClick={onClose} />}
      footer={
        <>
          <S.CloseButton onClick={onClose}>Fechar</S.CloseButton>

          <Button
            type="primary"
            icon={<CopyOutlined />}
            disabled={
              (tabActiveKey === 'bom' && (!bom || isBomLoading || isBomError)) ||
              (tabActiveKey === 'fabricante' &&
                (isScrapingInitialLoading ||
                  isScrapingLoading ||
                  !debouncedScrapingSearch ||
                  !scraping ||
                  isError))
            }
            onClick={() =>
              handleCopyFormattedJSON((tabActiveKey === 'bom' ? bom : scraping) as any)
            }
          >
            Copiar Conteúdo
          </Button>
        </>
      }
    >
      {antdToastContextHolder}

      {productTabItems.map((item) => {
        if (item.key === tabActiveKey) {
          return item.content
        }

        return null
      })}
    </S.Drawer>
  )
}

export { SpecificationsHelpDrawer }
