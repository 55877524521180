import { useState } from 'react'

import { PlusOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { shallow } from 'zustand/shallow'

import * as S from './styles'

import { useProductTypeFilters } from '@/app'
import { useToggle } from '@/common'
import { CustomPagination, LoadingSpinner } from '@/components'
import {
  CreateProductTypeModal,
  ProductType,
  ProductTypeModal,
  ProductTypeTable,
  useProductTypes,
} from '@/features/BG'
import { PageLayout } from '@/layouts'

const ProductTypesPage = () => {
  const [isCreateProductTypeModalOpen, toggleCreateProductTypeModal] = useToggle(false)
  const [isProductTypeModalOpen, toggleProductTypeModal] = useToggle(false)

  const [selectedProductType, setSelectedProductType] = useState<ProductType | undefined>(undefined)

  const { productTypeFilters, setFilters } = useProductTypeFilters(
    (state) => ({
      productTypeFilters: state.filters,
      setFilters: state.setFilters,
    }),
    shallow,
  )

  const {
    data: productTypes,
    isLoading: isProductTypesLoading,
    isFetching: isProductTypesFetching,
  } = useProductTypes(productTypeFilters)

  function handleEditProductType(productType: ProductType | undefined) {
    setSelectedProductType(productType)
    toggleProductTypeModal()
  }

  function handlePagination(page: number, pageSize?: number) {
    setFilters({ page, per_page: pageSize })
  }

  const PageHeader = () => {
    return (
      <S.Header>
        <S.TitleContainer>
          <S.Title level={4}>Tipo de Produto</S.Title>

          <S.Divider thickness={2} type="vertical" />

          {productTypes && (
            <S.TotalProductTypesContent>
              <S.TotalProductTypesValue>{productTypes.total}</S.TotalProductTypesValue>
              {productTypes.total > 1 ? 'encontrados' : 'encontrado'}
            </S.TotalProductTypesContent>
          )}

          {(isProductTypesLoading || isProductTypesFetching) && <LoadingSpinner />}
        </S.TitleContainer>

        <Button type="primary" icon={<PlusOutlined />} onClick={toggleCreateProductTypeModal}>
          Criar tipo de produto
        </Button>
      </S.Header>
    )
  }

  return (
    <PageLayout title="Tipo de Produto">
      {isCreateProductTypeModalOpen && (
        <CreateProductTypeModal
          isOpen={isCreateProductTypeModalOpen}
          onClose={toggleCreateProductTypeModal}
        />
      )}

      {isProductTypeModalOpen && (
        <ProductTypeModal
          isOpen={isProductTypeModalOpen}
          onClose={() => handleEditProductType(undefined)}
          data={selectedProductType as ProductType}
        />
      )}

      <S.Container>
        <PageHeader />

        <ProductTypeTable
          data={productTypes?.product_types || []}
          isLoading={isProductTypesLoading}
          handleEditProductType={handleEditProductType}
        />

        <S.PaginationContainer>
          <CustomPagination
            scrollToTop
            page={productTypeFilters.page || 1}
            pageSize={productTypeFilters.per_page || 20}
            totalItems={productTypes?.total || 0}
            totalPages={productTypes?.total_pages || 1}
            isLoading={isProductTypesLoading}
            changePageValue={(page, pageSize) => handlePagination(page, pageSize)}
          />
        </S.PaginationContainer>
      </S.Container>
    </PageLayout>
  )
}

export { ProductTypesPage }
