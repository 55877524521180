import { QueryFunctionContext, useQuery } from '@tanstack/react-query'

import { ProductTypeGroupsField } from '../../types'
import { productKeys } from '../query-keys'

import { api } from '@/services'

async function fetchProductTypeGroupsField({
  queryKey,
}: QueryFunctionContext<ReturnType<(typeof productKeys)['groupField']>>) {
  const [{ vtexId }] = queryKey

  const { data } = await api.get<ProductTypeGroupsField[]>(`product_types/${vtexId}/field_groups`)

  return data
}

export const useProductTypeGroupsField = (id: number, enabled = true) => {
  return useQuery({
    queryKey: productKeys.groupField(id),
    queryFn: fetchProductTypeGroupsField,
    keepPreviousData: true,
    enabled: enabled,
  })
}
