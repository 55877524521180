import { Form as AntForm, Checkbox } from 'antd'
import styled from 'styled-components'

export const CheckboxGroup = styled(Checkbox.Group)`
  flex-direction: column;
`
export const Form = styled(AntForm)`
  gap: 0px;

  .ant-form-item {
    margin: 0px;
    padding: 0px;
  }

  .ant-checkbox-group {
    display: flex;
    flex-direction: column;
  }

  .ant-collapse-expand-icon {
    color: ${(props) => props.theme.colors.darkGreen};
  }
`

export const DateContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
