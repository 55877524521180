import { Image } from 'antd'

import { useMarketplaceStatus } from '../..'
import * as S from './styles'

import anymarketLogo from '@/assets/logo-anymarket.svg'
import vtexLogo from '@/assets/logo-vtex.svg'
import { LoadingSpinner } from '@/components'

const MarketplaceStatus = () => {
  const { data, isLoading, isFetching, isError } = useMarketplaceStatus()

  return (
    <S.Container>
      {isError ? (
        <S.MarketplaceContainer>
          <S.Status $textStatus={'Indisponível'}>Status Indisponível</S.Status>
          <S.OfflineIcon />
        </S.MarketplaceContainer>
      ) : (
        <>
          <S.MarketplaceContainer>
            <S.Text>Status:</S.Text>

            <Image src={vtexLogo} width={60} alt="Logo VTEX" preview={false} />

            <S.Status $textStatus={data?.vtex_integration}>{data?.vtex_integration}</S.Status>
            {data?.vtex_integration === 'Operacional' ? <S.OnlineIcon /> : <S.OfflineIcon />}
          </S.MarketplaceContainer>

          <S.Divider type="vertical" />

          <S.MarketplaceContainer>
            <S.Text>Status:</S.Text>
            <S.AnyMarketImageContainer>
              <Image src={anymarketLogo} width={28} alt="Logo AnyMarket" preview={false} />
            </S.AnyMarketImageContainer>
            <S.Status $textStatus={data?.anymarket_integration}>
              {data?.anymarket_integration}
            </S.Status>
            {data?.anymarket_integration === 'Operacional' ? <S.OnlineIcon /> : <S.OfflineIcon />}
          </S.MarketplaceContainer>
        </>
      )}

      {(isLoading || isFetching) && <LoadingSpinner size={'small'} />}
    </S.Container>
  )
}

export { MarketplaceStatus }
